<template>
  <div>
    <p v-if="label">{{ label }}</p>
    <br/>
    <editor
        ref="text_editor"
        api-key="6pd16nn5jw8fqh5aayva56pirbe9c17rlgtbs6mpx6n81ly5"
        :init="init"
        v-model="new_value"
        :height="height"
        @input="$emit('input', new_value)"/>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  props: ["value", "height", "label", "menubar", "margins"],
  components: {
    editor: Editor,
  },
  data() {
    return {
      new_value: "",
    };
  },
  computed: {
    init() {
      return {
        language: "en",
        height: this.height,
        inline: false,
        menubar: true,
        paste_data_images: true,
        font_formats:
            "Arial Black=arial black,avant garde; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=Verdana; Calibri Light=Calibri Light; Calibri Regular=Calibri Regular; Helvetica Neue Light=Helvetica;",
        content_style:
            "body { line-height: 1.4; padding-top:" +
            this.paddingTop + "mm;background-repeat: no-repeat;background-size: 820px 1160px;padding-bottom:" +
            this.paddingBottom + "mm;padding-left:" +
            this.paddingLeft + "mm;padding-right:" +
            this.paddingRight + "mm;} .pagebreak{height:" +
            this.paddingTop +  "mm;}",
        toolbar_sticky: true,
        pagebreak_split_block: true,
        pagebreak_separator: "<pagebreak style='height:" + this.paddingTop + "mm;'/>",
        lineheight_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt 48pt 72pt 96pt",
        fontsize_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt 48pt 72pt 96pt",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount pagebreak",
        ],
        toolbar:
            "undo redo | formatselect | fontselect | lineHeight | fontsizeselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | pagebreak | table",
        setup: (editor) => {
          this.editor = editor;
          editor.on('Load', () => {
            this.new_value = this.getValue;
          });
        },
      };
    },
    getValue() {
      return this.value;
    },
    paddingTop() {
      return this.margins ? this.margins.margin_top : 0;
    },
    paddingBottom() {
      return this.margins ? this.margins.margin_bottom : 0;
    },
    paddingLeft() {
      return this.margins ? this.margins.margin_left : 0;
    },
    paddingRight() {
      return this.margins ? this.margins.margin_right : 0;
    },
  },
  watch: {
    value: {
      handler() {
        this.new_value = this.getValue;
        this.$emit("input", this.new_value);
      },
    },
    paddingTop: {
      handler() {
        this.key = this.key + 1;
      },
    },
    paddingBottom: {
      handler() {
        this.key = this.key + 1;
      },
    },
    paddingLeft: {
      handler() {
        this.key = this.key + 1;
      },
    },
    paddingRight: {
      handler() {
        this.key = this.key + 1;
      },
    },
  },
};
</script>

<style>
.tox-notifications-container {
  display: none !important;
}

.tinymce-content p {
  padding: 0;
  margin: 2px 0;
}

.tox-tinymce--toolbar-sticky-on .tox-editor-header {
  top: 60px !important;
}

.pagebreak {
  background: #f4f4f4;
}
</style>