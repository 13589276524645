<template>
  <v-data-table
      :loading="tableLoading"
      :disabled="tableLoading"
      :headers="fields"
      :items="items"
      class="elevation-1"
      item-key="id"
      :disable-pagination="true"
      :disable-sort="true"
      :hide-default-footer="true"
  >
    <template v-slot:body="{ items }">
      <tbody>
      <tr v-for="item in items" :key="item.id">
        <template v-for="column in item.columns">
          <template v-if="column.type === 'text'">
            <td :key="column.text+column.type">
              {{ $lodash.truncate(column.label, { length: 40 }) }}
            </td>
          </template>
          <template v-else>
            <td :key="column.text+column.type">
              <v-checkbox
                  :class="['text-center', column.class === 'pseudo-filled' && column.selected ? column.class : '']"
                  v-model="column.selected"
                  :value="column.id"
                  style="margin-top: 4px; height: 32px"
              />
            </td>
          </template>
        </template>
      </tr>
      <tr v-if="items.length === 0">
        <td class="text-center grey--text" :colspan="fields.length + 1">
          <span v-if="!tableLoading">{{ $store.getters.translate("nothing_found") }}</span>
          <span v-else>{{ $store.getters.translate("loading") }}</span>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["items", "tableLoading", "fields", "selected_record"],
  name: "MiniList",
  components: {},
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.text-center >>> .v-input__slot {
  align-items: center;
  justify-content: center;
}

.pseudo-filled{
  opacity: 0.5;
}
</style>