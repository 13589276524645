<template>
  <v-card class="mb-2" :loading="loading">
    <v-toolbar dense flat>
      <v-spacer/>
      <v-tooltip v-if="!profile" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="canDelete(record.id || 0)"
            @click="$emit('archive')"
            v-bind="attrs"
            v-on="on"
            text
            small
            color="secondary">
            <v-icon color="error">mdi-archive</v-icon>
          </v-btn>
        </template>
        <span>{{ $store.getters.translate("archive") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="canEdit(record.id || 0)"
            @click="save"
            v-bind="attrs"
            v-on="on"
            text
            small
            color="primary">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $store.getters.translate("save") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-divider/>
    <v-row>
      <v-col style="margin: 25px" lg="3" sm="12" md="12" class="d-flex justify-center">
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <base-profile-photo modal_name="base_profile_photo" :person="record.person" @refresh="save"/>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>
                <slot name="below_photo"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col style="margin-right: 25px">
        <v-tabs
          v-if="record.person"
          v-model="tab"
          :centered="false"
          :grow="false"
          :vertical="false"
          :right="false"
          :icons-and-text="false">
          <v-tab>{{ $store.getters.translate("general_info") }}</v-tab>
          <v-tab>{{ $store.getters.translate("contact_information") }}</v-tab>
          <v-tab>{{ $store.getters.translate("additional_information") }}</v-tab>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                        :disabled="!canEdit(record.id || 0)"
                        :label="$store.getters.translate('first_name')"
                        v-model="record.person.first_name"
                        validate-on-blur
                        required>
                      <template #label>{{ $store.getters.translate('first_name') }}<span class="red--text"><strong>*</strong></span></template>
                    </v-text-field>
                    <v-text-field
                        :disabled="!canEdit(record.id || 0)"
                        :label="$store.getters.translate('insertion')"
                        v-model="record.person.insertion"/>
                    <v-text-field
                        :disabled="!canEdit(record.id || 0)"
                        :label="$store.getters.translate('last_name')"
                        v-model="record.person.last_name"
                        required>
                      <template #label>{{ $store.getters.translate('last_name') }}<span class="red--text"><strong>*</strong></span></template>
                    </v-text-field>
                    <v-text-field
                        :disabled="!canEdit(record.id || 0)"
                        :label="$store.getters.translate('initials')"
                        v-model="record.person.initials"/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <template v-for="field in usual_fields">
                      <template v-if="field.name === 'jobtitle'">
                        <base-field
                            v-if="module_name === 'profile'"
                            :disabled="!canEdit(record.id || 0)"
                            :key="field.name"
                            :value="record.person[user_type][field.name]"
                            :field="field"
                            @change="usualFieldDataChanged"/>
                        <base-field
                            v-else
                            :disabled="!canEdit(record.id || 0)"
                            :key="field.name"
                            :value="record[field.name]"
                            :field="field"
                            @change="usualFieldDataChanged"/>
                      </template>
                      <!--EXCLUDE NAME FIELD BECAUSE IT`S ALREADY SHOWN BEFORE AS FIRST AND LAST NAME -->
                      <template v-else-if="field.name !== 'name'">
                        <base-field
                            :disabled="!canEdit(record.id || 0)"
                            :key="field.name"
                            :value="record.person[field.name]"
                            :field="field"
                            @change="usualFieldDataChanged"/>
                      </template>
                    </template>
                    <v-text-field
                        :disabled="!canEdit(record.id || 0)"
                        v-if="record.person.user"
                        :label="$store.getters.translate('user_email')"
                        v-model="record.person.user.email"/>
                    <email-addresses
                        v-model="record.person.emailaddresses"
                        @change="save"/>
                    <base-addresses
                        @change="save"
                        v-model="record.person.addresses"/>
                    <template v-if="tags_required">
                      <base-tags v-if="module_name === 'profile'" v-model="record.person[user_type].tags" :modal_name="user_type + 's'"/>
                      <base-tags v-else v-model="record.tags" :modal_name="user_type + 's'"/>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <template v-if="record.custom_fields">
                      <template v-for="field in custom_fields">
                        <base-field
                            v-if="module_name === 'profile'"
                            :disabled="!canEdit(record.id || 0)"
                            :key="field.name"
                            :value="record.person[user_type].custom_fields[field.name]"
                            :field="field"
                            @change="customFieldDataChanged"/>
                        <base-field
                            v-else
                            :disabled="!canEdit(record.id || 0)"
                            :key="field.name"
                            :value="record.custom_fields[field.name]"
                            :field="field"
                            @change="customFieldDataChanged"/>
                      </template>
                    </template>
                    <base-log-field :model_type="user_type" :model_id="record.id"/>
                    <v-switch
                        v-if="$store.getters.getUser.superuser && module_name === 'employees' && record.person.user"
                        v-model="record.person.user.flange_management"
                        @change="updateFlangeManagement"
                        :label="$store.getters.translate('flange_management')"/>
                    <v-autocomplete
                        v-if="$store.getters.getUser.superuser && module_name === 'employees' && record.person.user"
                        :label="$store.getters.translate('platforms')"
                        v-model="record.assets"
                        :items="platforms"
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        item-value="id"
                        item-text="name"
                        outlined
                        dense
                        hide-details
                        clearable
                        :return-object="true"
                        class="mb-5 mt-5"/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import BaseProfilePhoto from "./BaseProfilePhoto";
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
import BaseLogField from "./BaseLogField";
import helpFunctions from "../../plugins/helpFunctions";
export default {
  name: "BaseProfile",
  props: ["record", "module_name", "user_type", "no_notification", "profile"],
  components: {
    BaseProfilePhoto,
    BaseAddresses,
    BaseLogField,
  },
  data() {
    return {
      tab: 0,
      show_create_user_dialog: false,
      loading: false,
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      platforms: [],
    };
  },
  mounted() {
    this.getPlatforms();
    this.getFields();
  },
  methods: {
    canEdit(id = 0) {
      if (this.profile) {
        return true;
      }
      return this.$can("edit", this.$createEntity(this.user_type, {id}));

    },
    canDelete(id = 0) {
      if (this.profile) {
        return true;
      }
      return this.$can("delete", this.$createEntity(this.user_type, {id}));
    },
    save() {
      let error = false;
      if (this.record.person.first_name === '' || this.record.person.last_name === '') {
        this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('name'));
        error = true;
      }
      this.usual_fields.forEach((field) => {
        if (field.mandatory && field.field_type !== 'boolean' && field.name !== 'jobtitle' && field.name !== 'name') {
          if(!this.record.person[field.name] || this.record.person[field.name] === '') {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        }
      });
      if(!error) {
        this.$emit('change');
      }
    },
    updateFlangeManagement() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/employees/update-flange-management", {
            id: this.record.id,
            flange_management: this.record.person.user.flange_management,
          }).then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
          }).catch(() => {
            this.$toasted.error(this.$store.getters.translate("error"));
            this.loading = false;
          });
    },
    async getPlatforms() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/assets?root=true")
          .then((response) => {
            this.loading = false;
            this.platforms = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    usualFieldDataChanged(data) {
      if(data[0] === 'jobtitle') {
        if(this.module_name === 'profile') {
          this.record.person[this.user_type][data[0]] = data[1];
        }
        else {
          this.record[data[0]] = data[1];
        }
      }
      else {
        this.record.person[data[0]] = data[1];
      }
    },
    customFieldDataChanged(data) {
      if(this.module_name === 'profile') {
        this.record.person[this.user_type].custom_fields[data[0]] = data[1];
      }
      else {
        this.record.custom_fields[data[0]] = data[1];
      }
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.user_type, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.label-custom {
  border: 0px !important;
  font-style: italic;
}
</style>