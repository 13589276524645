<template>
  <div>
    <p v-if="disabled">{{$store.getters.translate(label)}}</p>
    <editor :placeholder="disabled ? '' : $store.getters.translate(label)" :disabled="disabled" :editorOptions="editorOptions" v-model="new_value"/>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  props: ["value", "label", "disabled"],
  components: {
    editor: VueEditor,
  },
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      new_value: "",
    };
  },
  watch: {
    value: {
      handler() {
        this.new_value = this.getValue;
      },
    },
    new_value: {
      handler() {
        this.$emit("input", this.new_value);
      },
    },
  },
  computed: {
    getValue() {
      return this.value;
    },
  },
};
</script>

<style>
.tox-notifications-container {
  display: none !important;
}
</style>