<template>
  <div>
    <v-combobox
      :disabled="!!isDisabled"
      :label="$store.getters.translate('tags')"
      v-model="new_value"
      :items="tags"
      outlined
      chips
      deletable-chips
      small-chips
      :multiple="!single"
      item-value="name.en"
      item-text="name.en"
      :return-object="false"
      append-icon="mdi-tag"
      ref="menu"
      @click:append="getTags();$refs.menu.blur();$refs['tags_' + modal_name].open();"
      dense
      hide-details
      height="auto"
      clearable/>
    <BaseDraggableModal
        :name="'tags_' + modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="'tags_' + modal_name"
        tabindex="0"
        @blur.native="$refs['tags_' + modal_name].close()">
      <v-card :loading="loading">
        <BaseModal>
          <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
            <v-btn icon @click="$refs['tags_' + modal_name].close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn v-if="canCreate()" text small @click="$refs['tags_' + modal_name].close()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="tags"
                  :loading="loading"
                  selectable-key="id">
                <template v-slot:item.updated_at="{ item }">{{
                    moment(item.updated_at).format("DD-MM-YYYY hh:mm")
                  }}</template>
                <template v-slot:item.actions="{ item }">
                  <v-icon v-if="canDelete(item.id)" small @click="archive(item.id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
          <v-footer slot="footer">
            <v-text-field
              :disabled="!canCreate()"
              append-outer-icon="mdi-plus"
              @click:append-outer="save()"
              v-model="record.name"
              :label="$store.getters.translate('create')"
              single-line/>
          </v-footer>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["value", "modal_name", "single", "available_tags", "isDisabled"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("tag"),
        name: "tags",
        model: "tag"
      },
      new_value: [],
      tags: [],
      loading: false,
      record: {},
      headers: [
        {
          text: this.$store.getters.translate("name"),
          align: "start",
          sortable: true,
          value: "name.en",
        },
        { text: this.$store.getters.translate("updated"), value: "updated_at" },
        {
          text: this.$store.getters.translate("actions"),
          sortable: false,
          align: "right",
          value: "actions",
        },
      ],
    };
  },
  created() {
    this.new_value = this.value;
    this.getTags();
  },
  methods: {
    ...helpFunctions.modal_functions,
    update() {
      this.$emit("input", this.new_value);
      this.$emit("change");
    },
    async save() {
      if(!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, usual_fields_required)) {
          this.loading = true;
          let id = await helpFunctions.createModel(this.$toasted, this.page.name, this.record);
          if(id) {
            this.record = {};
            this.$refs['tags_' + this.modal_name].close();
            await this.getTags();
          }
          this.loading = false;
        }
      }
    },
    async getTags() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/tags")
        .then((response) => {
          this.loading = false;
          this.tags = response.data;
          this.$emit("tags", this.tags);
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    async archive(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, id)) {
            await this.getTags();
          }
          this.loading = false;
        }
      });
    },
    canCreate() {
      return this.$can("create", this.$createEntity("tag"));
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity("tag", {id}));
    },
    canDelete(id = 0) {
      return this.$can("delete", this.$createEntity("tag", {id}));
    },
  },
  watch: {
    new_value: {
      handler() {
        this.update();
      },
    },
    value: {
      handler() {
        this.new_value = this.value;
      },
    },
    available_tags: {
      handler() {
        if(this.available_tags.length > 0) {
          this.tags = this.available_tags;
        }
        else {
          this.getTags();
        }
      },
    },
  },
};
</script>