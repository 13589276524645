var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[(_vm.record)?_c('v-tabs',{attrs:{"centered":false,"grow":false,"vertical":false,"right":false,"icons-and-text":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{on:{"click":function($event){return _vm.$emit('refresh')}}},[_vm._v(_vm._s(_vm.$store.getters.translate("general_info")))]),(_vm.module_name === 'companies' ||
      _vm.module_name === 'workflows' || (_vm.record.custom_fields && _vm.record.custom_fields.length > 0))?_c('v-tab',{on:{"click":function($event){return _vm.$emit('refresh')}}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("additional_information"))+" ")]):_vm._e(),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-spacer'),_vm._t("extra_buttons"),(_vm.module_name === 'punchlists')?_c('v-btn',{staticClass:"ma-3",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('exportPdf')}}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("export_to_pdf"))+" "),_c('v-icon',{attrs:{"right":"","x-small":""}},[_vm._v("mdi-download")])],1):_vm._e(),(!_vm.no_delete && _vm.canDelete(_vm.record.id || 0))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('archive')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-archive")])],1)]}}],null,false,2309645008)},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("archive")))])]):_vm._e(),(_vm.canEdit(_vm.record.id || 0))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('change')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,2430653388)},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("save")))])]):_vm._e()],2),_c('v-tab-item',[_c('v-card',{staticStyle:{"margin":"25px"},attrs:{"flat":"","tile":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._t("main_fields")],2)],1)],1)],1)],1),(_vm.module_name === 'companies' || _vm.module_name === 'workflows' || (_vm.record.custom_fields && _vm.record.custom_fields.length > 0))?_c('v-tab-item',[_c('v-card',{staticStyle:{"margin":"25px"},attrs:{"flat":"","tile":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._t("module_fields")],2)],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }