<template>
  <v-card :disabled="loading" :loading="loading">
    <v-tabs
      v-if="record"
      v-model="tab"
      :centered="false"
      :grow="false"
      :vertical="false"
      :right="false"
      :icons-and-text="false">
      <v-tabs-slider/>
      <v-tab @click="$emit('refresh')">{{ $store.getters.translate("general_info") }}</v-tab>
      <v-tab @click="$emit('refresh')" v-if="module_name === 'companies' ||
      module_name === 'workflows' || (record.custom_fields && record.custom_fields.length > 0)">
        {{ $store.getters.translate("additional_information") }}
      </v-tab>
      <v-toolbar dense flat>
        <v-spacer/>
        <slot name="extra_buttons"/>
        <v-btn :disabled="loading" :loading="loading" v-if="module_name === 'punchlists'" class="ma-3" small @click="$emit('exportPdf')" color="primary">
          {{ $store.getters.translate("export_to_pdf") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
<!--    <v-btn :disabled="loading" :loading="loading" v-if="module_name === 'punchlists'" class="ma-3" small @click="$emit('exportExcel')" color="primary">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>-->
        <v-tooltip top v-if="!no_delete && canDelete(record.id || 0)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="$emit('archive')"
                v-bind="attrs"
                v-on="on"
                text
                small
                class="mr-2"
                color="secondary">
              <v-icon color="error">mdi-archive</v-icon>
            </v-btn>
          </template>
          <span>{{ $store.getters.translate("archive") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="canEdit(record.id || 0)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="$emit('change')"
              v-bind="attrs"
              v-on="on"
              text
              small
              color="primary">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>{{ $store.getters.translate("save") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-tab-item>
        <v-card style="margin: 25px" flat tile>
          <v-card-text>
            <v-row>
              <v-col>
                <slot name="main_fields"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="module_name === 'companies' || module_name === 'workflows' || (record.custom_fields && record.custom_fields.length > 0)">
        <v-card style="margin: 25px" flat tile>
          <v-card-text>
            <v-row>
              <v-col>
                <slot name="module_fields"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  props: ["record", "module_name", "loading", "model", "no_delete"],
  data() {
    return {
      tab: 0,
      show_create_user_dialog: false,
    };
  },
  methods: {
    canEdit(id) {
      return this.$can('edit', this.$createEntity(this.model, {id}))
    },
    canDelete(id = 0) {
      return this.$can('delete', this.$createEntity(this.model, {id}))
    },
  },
};
</script>
<style scoped>
.label-custom {
  border: 0px !important;
  font-style: italic;
}
</style>