import Vue from 'vue';
import '../scss/vuetify/overrides.scss'
import '@mdi/font/css/materialdesignicons.css'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyConfirm from 'vuetify-confirm'
Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

const theme = {
  primary: '#497485',
  info: '#5E5E5E',
  success: '#02b325',
  accent: '#f0b216',
  default: '#636363',
  secondary: '#828282'
}

import nl from 'vuetify/es5/locale/nl'
import en from 'vuetify/es5/locale/en'

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  lang: {
    locales: { nl, en },
    current: 'nl',
  },
});

Vue.use(VuetifyConfirm, { vuetify })

export default vuetify;
