<template>
  <div>
    <template>
      <textarea
        :disabled="true"
        :style="'height:' + getHeight"
        ref="textarea"
        id="log_textarea"
        v-model="new_value"
        :placeholder="$store.getters.translate('log')"
        @click="setFocus()"/>
      <v-text-field
        :disabled="!canEdit()"
        :placeholder="$store.getters.translate('add_to_log')"
        single-line
        dense
        v-model="add_line"
        @keyup.enter="insertContent"
        class="custom-text-field"
        hide-details
        ref="add_line">
        <v-icon :disabled="!canEdit()" @click="insertContent" slot="append" color="primary">
          mdi-plus
        </v-icon>
      </v-text-field>
    </template>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["model_type", "model_id", "height"],
  data() {
    return {
      new_value: null,
      add_line: null,
      set_first_focus: true,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    canCreate() {
      return this.$can("create", this.$createEntity(this.model_type));
    },
    canEdit() {
      return this.$can("edit", this.$createEntity(this.model_type, {id : this.model_id}));
    },
    canDelete() {
      return this.$can("delete", this.$createEntity(this.model_type, {id : this.model_id}));
    },
    load() {
      if(this.model_id) {
        this.$http
            .get(this.$store.getters.appUrl + "v2/model-logs?model_type=" + this.model_type + "&model_id=" + this.model_id)
            .then((response) => {
              this.new_value = response.data;
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
    },
    save() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/model-logs", {
          model_type: this.model_type,
          model_id: this.model_id,
          log: this.new_value,
        })
        .then(() => {
          this.$emit("change", this.new_value);
          this.load();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    setFocus() {
      if (this.set_first_focus) {
        this.$refs.add_line.focus();
        this.set_first_focus = false;
      }
    },
    insertContent() {
      if (this.add_line) {
        let line = "[" + moment().format("DD-MM-YYYY HH:mm:ss") + " - " + this.$store.getters.getUser.person.casual_name + "] " + this.add_line;
        if (!this.new_value) {
          this.new_value = line;
        } else {
          this.new_value = line + "\n" + this.new_value;
        }
        this.add_line = null;
        this.save();
      }
    },
  },
  computed: {
    getHeight() {
      if (this.height) {
        return this.height;
      }
      return "150px";
    },
  },
  watch: {
    model_id: {
      handler() {
        this.load();
      },
    },
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  font-size: 12px !important;
  border-top: 1px solid #9E9E9E;
  border-left: 1px solid #9E9E9E;
  border-right: 1px solid #9E9E9E;
  border-bottom: 1px solid #9E9E9E;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 5px;
  margin-bottom: 0px !important;
  resize: none;
}

textarea:focus {
  outline: none !important;
}

input {
  padding: 0 !important;
}

.custom-text-field {
  padding: 5px;
  border-bottom: 1px solid #9E9E9E;
  border-left: 1px solid #9E9E9E;
  border-right: 1px solid #9E9E9E;
  border-radius: 5px;
  width: 100%;
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 15px;
}
</style>