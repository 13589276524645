class Entity{
    constructor(fields) {
        for(let key in fields){
            if(Object.prototype.hasOwnProperty.call(fields, key)){
                this[key] = fields[key];
            }
        }
    }
}

export default function (className, fields = {}){
    let entity = new Entity(fields);
    Object.defineProperty (Entity, 'name', {value: className.toLowerCase()});
    return entity;
}