<template>
  <div>
    <v-btn small @click="openModal(modal_name); showHandler()">
      {{ $store.getters.translate("messages") }}
    </v-btn>
    <v-divider vertical/>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="modal_name">
      <v-card :disabled="loading" :loading="loading">
        <BaseModal>
          <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
            <v-card-title>{{ $store.getters.translate("messages") }}</v-card-title>
            <v-spacer/>
            <v-btn icon @click="closeModal(modal_name); showHandler()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text slot="content">
            <v-row>
              <v-col>
                <v-data-table
                    :headers="headers"
                    :items="notifications"
                    :loading="loading"
                    :items-per-page="5">
                  <template v-slot:item.data="{ item }">
                    <div class="mt-2 mb-2">
                      <p v-html="item.data.message"/>
                      <v-chip x-small label>
                        <v-icon left x-small>mdi-eye</v-icon>
                        <span>{{ item.read_at ? moment(item.read_at).format("DD-MM-YYYY") : $store.getters.translate("has_not_been_read") }}</span>
                      </v-chip>
                      <v-chip class="ml-2" x-small label>
                        <v-icon left x-small>mdi-plus</v-icon>
                        <span>{{ item.created_at ? moment(item.created_at).format("DD-MM-YYYY") : "-" }}</span>
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon @click="deleteNotification(item.id)" right>
                      mdi-close
                    </v-icon>
                  </template>
                </v-data-table>
                <base-message :label="$store.getters.translate('send_notification')" v-model="record.message" modal_name="base_message"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions slot="footer">
          <v-btn @click="save()" :loading="loading" :disabled="!record.message" text>
            {{ $store.getters.translate("save_template") }}
          </v-btn>
          <v-spacer/>
          <v-btn @click="sendNotification()" :loading="loading" :disabled="isDisabled" color="primary" text>
            {{ $store.getters.translate("send") }}
          </v-btn>
        </v-card-actions>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["user_id", "recipient", "phonenumber", "modal_name"],
  data() {
    return {
      loading: false,
      record: {},
      notifications: [],
      headers: [
        {
          text: this.$store.getters.translate("message"),
          align: "start",
          sortable: true,
          value: "data",
          width: "90%",
        },
        {
          text: this.$store.getters.translate("actions"),
          sortable: false,
          align: "right",
          value: "actions",
          width: "10%",
        },
      ],
      dialog_options: {},
      dialogScrolling: {
        scroll : 0,
        dialog : 0,
        baseDialog : 0,
      }
    };
  },
  created() {
    this.dialog_options = helpFunctions.dialog_options;
    this.dialogScrolling.baseDialog = helpFunctions.dialog_options.top;
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...helpFunctions.modal_functions,
    getPageHeight(){
      let body = document.body,
          html = document.documentElement;

      return Math.max(body.scrollHeight, body.offsetHeight,
          html.clientHeight, html.scrollHeight, html.offsetHeight);
    },
    dragEnd(event){
      this.dialogScrolling.dialog = +event.top + document.documentElement.scrollTop;
    },
    handleScroll () {
      this.dialogScrolling.dialog = +this.dialogScrolling.dialog
          + (document.documentElement.scrollTop - this.dialogScrolling.scroll);

      this.dialogScrolling.scroll = +document.documentElement.scrollTop;
    },
    getTopOfDialog(){
      return +document.querySelector('.dialog-drag, .dialog-drag.fixed').style.top.replace('px', '');
    },
    getDomTopOfDialog(){
      return +document.querySelector('.dialog-drag, .dialog-drag.fixed').getBoundingClientRect().top;
    },
    getDomHeightOfDialog(){
      return Math.ceil(document.querySelector('.dialog-drag, .dialog-drag.fixed').getBoundingClientRect().height);
    },
    sendNotification() {
      this.loading = true;
      this.record.user_id = this.user_id;
      this.$http
        .post(this.$store.getters.appUrl + "v2/notifications", this.record)
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("success"));
          this.notifications.unshift({
            data: { message: this.record.message },
            read_at: null,
          });
          this.record = {};
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    save() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/messagetemplates", this.record)
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    getNotifications() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/get-notifications/" + this.user_id)
          .then((response) => {
            this.loading = false;
            this.notifications = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteNotification(id) {
      this.loading = true;
      this.$http
        .delete(this.$store.getters.appUrl + "v2/notifications/" + id)
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
          this.getNotifications();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    isRead(date) {
      return !!date;
    },
    showHandler() {
      this.record = {};
      this.getNotifications();
      if (this.recipient) {
        this.to = this.recipient;
      }
    },
  },
  computed: {
    isDisabled() {
      return !this.record.message;
    },
  },
  watch: {
    'dialogScrolling.dialog' : function (top) {
      if(top !== this.getTopOfDialog()) {
        document.querySelector('.dialog-drag, .dialog-drag.fixed').style.top = `${top}px`;
      }
    }
  },
};
</script>