<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar dense flat>
          <!--SEARCH-->
          <v-toolbar-title>
            <v-text-field
              v-model="search"
              v-debounce:230ms.lock="launchSearch"
              @click:clear="clearSearch"
              :label="$store.getters.translate('search')"
              single-line
              hide-details
              clearable
              append-icon="mdi-magnify"/>
          </v-toolbar-title>
          <v-toolbar-title>
            <v-select v-if="page.name !== 'fields'"
              class="ml-10 mt-5"
              v-model="options.search_column"
              :items="search_fields"
              item-value="value"
              item-text="text"
              clearable
              @change="launchSearch"/>
          </v-toolbar-title>
          <!--EXACT SEARCH-->
          <v-toolbar-title v-if="page.name !== 'fields'">
            <v-switch class="ml-10 mt-7" :label="$store.getters.translate('exact_search')" v-model="options.exact_search"/>
          </v-toolbar-title>
          <!--TO DO LIST-->
          <v-toolbar-title v-if="page.name === 'documents'">
            <v-btn-toggle class="ml-10" v-model="show_from_app">
              <v-btn x-small :value="true">{{ $store.getters.translate("to_do_list") }}</v-btn>
              <v-btn x-small :value="false">{{ $store.getters.translate("all") }}</v-btn>
            </v-btn-toggle>
          </v-toolbar-title>
          <v-spacer/>
          <!--EXCEL EXPORT-->
          <download-excel v-if="records.length > 0" class="v-btn v-btn--text theme--light v-size--small" :data="records" :name="page.name + '.xls'">
            {{ $store.getters.translate("export_to_excel") }}
            <v-icon small class="ml-2" color="primary">mdi-file-excel</v-icon>
          </download-excel>
          <v-divider class="mr-2 ml-2" vertical/>
          <!--DELETE-->
          <template v-if="canDelete() && selected.length > 0">
            <v-btn color="red" @click="bulkArchive" text small>
              {{ $store.getters.translate("delete") }} {{ selected.length }} {{ $store.getters.translate(page.name) }}
            </v-btn>
            <v-divider class="mr-2 ml-2" vertical/>
          </template>
          <!--DOWNLOAD DOCUMENTS-->
          <template v-if="page.name === 'documents' && selected.length > 0">
            <v-btn @click.stop="$emit('download', selected);" text small color="primary">
              {{ $store.getters.translate("download") }}
            </v-btn>
            <v-divider class="mr-2 ml-2" vertical/>
          </template>
          <!--IMPORT-->
          <base-import v-if="canCreate() && !base_table_options.no_import" @result="importData" :title="page.name"/>
          <!--CREATE-->
          <template v-if="canCreate() && !base_table_options.no_create">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <!--CREATE NEW RECORD-->
                <v-btn
                  v-if="page.name !== 'fields'"
                  @click="$emit('create', true)"
                  v-bind="attrs"
                  v-on="on"
                  text
                  small
                  color="primary">
                  {{ $store.getters.translate("create") }}
                  <v-icon small class="ml-2">mdi-plus</v-icon>
                </v-btn>
                <!--CREATE CUSTOM FIELD ON FIELDS PAGE-->
                <v-btn
                  v-else-if="page.name === 'fields' && selected_model !== 'Asset' && selected_model !== 'Punchlist' && selected_model !== 'PunchlistItem' &&
                  selected_model !== 'Role' && selected_model !== 'Tag' && selected_model !== 'Translation' && selected_model !== 'TypesAndSubtype' &&
                  selected_model !== 'Workflow' && selected_model !== 'User'"
                  @click="$emit('create', selected_model)"
                  v-bind="attrs"
                  v-on="on"
                  text
                  small
                  color="primary">
                  {{ $store.getters.translate("create") }}
                  <v-icon small class="ml-2">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $store.getters.translate("create") }}</span>
            </v-tooltip>
          </template>
          <!--SAVE-->
          <template v-if="page.name === 'fields' && canEdit()">
            <v-btn @click="save()" text small color="primary">
              {{ $store.getters.translate("save") }}
              <v-icon right small>mdi-content-save</v-icon>
            </v-btn>
          </template>
        </v-toolbar>
        <v-data-table
          @toggle-select-all="() => { this.selected = !this.selected.length ? this.getRecords.filter(el => this.canDelete(el.id)) : []; }"
          :headers="fields"
          :items="getRecords"
          :loading="loading"
          class="elevation-1"
          item-key="id"
          selectable-key="id"
          :show-select="!base_table_options.no_select"
          v-model="selected"
          :options.sync="options"
          :server-items-length="total_records"
          :disable-pagination="disable_pagination"
          :hide-default-footer="hide_default_footer"
          :footer-props="footer_props"
          :items-per-page.sync="options.itemsPerPage"
          @current-items="current = $event">
          <!--SELECT MODEL OR TAGS FIELD-->
          <template v-if="page.name === 'fields'" v-slot:top>
            <v-card flat>
              <v-card-text>
                <v-select :label="$store.getters.translate('model_type')" v-model="selected_model" :items="$lodash.sortBy(models, 'text')"/>
              </v-card-text>
            </v-card>
          </template>
          <template v-else-if="!base_table_options.no_tags" v-slot:top>
            <v-card flat>
              <v-card-text>
                <base-tags v-model="tags" :modal_name="page.name"/>
              </v-card-text>
            </v-card>
          </template>
          <!--CUSTOM DRAGGABLE TABLE FOR FIELDS MODEL ONLY-->
          <template v-if="page.name === 'fields'" v-slot:body="props">
            <draggable :list="props.items" tag="tbody" @end="endDrag(props.items)">
              <tr v-for="(field, index) in props.items" :key="index">
                <td><v-icon small class="page__grab-icon">mdi-arrow-all</v-icon></td>
                <td>{{ field.sort_order }}</td>
                <td>{{ field.id }}</td>
                <td v-if="field.custom_field == 1"><a @click="$emit('edit', field)">{{ $store.getters.translate(field.name) }}</a></td>
                <td v-else>{{ $store.getters.translate(field.name) }}</td>
                <td>{{ $store.getters.translate(field.field_type) }}</td>
                <td><v-checkbox v-model="field.mandatory" :value="field.mandatory"/></td>
                <td><v-checkbox v-model="field.show_in_search" :value="field.show_in_search"/></td>
                <td><v-checkbox v-model="field.use_in_import" :value="field.use_in_import"/></td>
                <td><v-checkbox v-model="field.show_on_creation" :value="field.show_on_creation"/></td>
                <td><v-checkbox v-model="field.show_on_update" :value="field.show_on_update"/></td>
                <!--ACTION BUTTONS-->
                <td style="text-align: right">
                  <v-icon v-if="canEdit(field.id)" @click="$emit('edit', field)" small class="mr-2">mdi-pencil</v-icon>
                  <v-icon v-if="+field.custom_field === 1" @click="deleteRecord(field.id)" small color="error">mdi-trash-can</v-icon>
                </td>
              </tr>
            </draggable>
          </template>
          <!--USUAL TABLE FOR ALL MODELS-->
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" @click.ctrl="handleCtrlClick(item)">
                <td v-if="page.name !== 'fields' && page.name !== 'roles'">
                  <v-checkbox v-model="selected" :value="item" style="margin-top: 4px; height: 32px" @click.shift="handleShiftClick(item)"/>
                </td>
                <td v-for="(key, index) in Object.keys(response_fields)" :key="index" @click.exact="$emit('edit', item)">
                  <!--CHECKBOXES/BOOLEAN VALUES-->
                  <template v-if="response_fields[key] === 'boolean'">
                    <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                    <template v-else>0</template>
                  </template>
                  <!--DATES-->
                  <template v-else-if="response_fields[key] === 'date' && item[key]">
                    {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
                  </template>
                  <!--DROPDOWNS-->
                  <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                    <template v-if="Array.isArray(item[key])">
                      <template v-if="item[key].length > 1">
                        <v-chip style="height: auto;" class="mr-2" x-small v-for="elem in item[key]" :key="elem">
                          {{ elem }}
                        </v-chip>
                      </template>
                      <template v-else>
                        {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                      </template>
                    </template>
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                    </template>
                  </template>
                  <!--LINKS-->
                  <template v-else-if="response_fields[key] === 'link'">
                    <a>
                      <v-avatar v-if="key === 'name' && base_table_options.avatar" class="mr-1" size="20">
                        <v-img style="border: 1px" :src="item.avatar ? item.avatar : '../../../assets/images/no-user.jpg'"/>
                      </v-avatar>
                      {{ $lodash.truncate(item[key], { length: 50 }) }}
                    </a>
                  </template>
                  <!--ID FIELDS-->
                  <template v-else-if="key === 'id' || key === 'internal_id'">
                    <a>{{ item[key] }}</a>
                  </template>
                  <!--TEXT FIELDS-->
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                  </template>
                </td>
                <!--ACTION BUTTONS-->
                <td style="text-align: right">
                  <v-icon v-if="page.name === 'projects'" small class="mr-2" @click="viewReport(item)">mdi-file-document</v-icon>
                  <v-icon v-if="base_table_options.copy && canCreate(item.id || 0)" small class="mr-2" @click="makeCopy(item)">mdi-content-copy</v-icon>
                  <v-icon v-if="canEdit(item.id || 0)" @click="$emit('edit', item)" small class="mr-2">mdi-pencil</v-icon>
                  <v-icon
                      v-if="canCreatePermission()"
                      @click="showPermissionManager(item)"
                      small
                      class="mr-2"
                      :class="[isCreatePermissionDisabled?'custom-disabled' : '']">
                    mdi-lock-outline
                  </v-icon>
                  <template v-if="!base_table_options.no_select">
                    <v-icon v-if="page.name !== 'roles' && canDelete(item.id)" @click="deleteRecord(item.id)" small color="error">mdi-trash-can</v-icon>
                  </template>
                </td>
              </tr>
              <tr v-if="getRecords.length === 0">
                <td class="text-center grey--text" :colspan="fields.length + 1">
                  <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
                  <span v-else>{{ $store.getters.translate("loading") }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <base-report modal_name="base_report" ref="base_report"/>
      </v-card>
    </v-col>
    <manage-by-roles ref="custom_permission_manager" :selected_record="selected_record" @disableManaging="manageCreatePermissionDisabledStatus" @refresh="showRecords()"/>
  </v-row>
</template>

<script>
import BaseImport from "./BaseImport.vue";
import BaseReport from "./BaseReport.vue";
import lodash from "lodash";
import Draggable from "vuedraggable";
import helpFunctions from "../../plugins/helpFunctions.js";
import ManageByRoles from "../../views/permissions/ManageByRoles";

export default {
  components: { Draggable, BaseImport, ManageByRoles, BaseReport },
  props: ["page"],
  data() {
    return {
      shortListOptions: {
        'entity_item_id' : 0,
        'entity_id' : 0,
      },
      selected_record: null,
      isCreatePermissionDisabled: false,
      loading: false,
      selected: [],
      all_records: [],
      records: [],
      total_records: 0,
      options: {},
      disable_pagination: false,
      hide_default_footer: false,
      footer_props: {
        itemsPerPageOptions: [15, 25, 50, 75, 100, 125, 150, 175, 200],
        rowsPerPageText: "Rows per page:",
      },
      filterPermissions: {
        display : {
          value: 'all',
          items: [
            {
              label : this.$store.getters.translate('all'),
              text : 'all',
            }, {
              label : this.$store.getters.translate('custom'),
              text : 'custom',
            }, {
              label : this.$store.getters.translate('default'),
              text : 'default',
            },
          ]
        },
        byEntityItem : {
          value: 'all',
          items: [
            {
              label : this.$store.getters.translate('no_filtered'),
              text : 'all',
            }
          ],
          isDisabled : true,
          isLoading : true,
        },
        byUser : {
          value: 'all',
          items: [
            {
              label : this.$store.getters.translate('no_filtered'),
              text : 'all',
            }
          ],
          isDisabled : true,
          isLoading : true,
        },
        byRole : {
          value: 'all',
          items: [
            {
              label : this.$store.getters.translate('no_filtered'),
              text : 'all',
            }
          ],
          isDisabled : true,
          isLoading : true,
        },
      },
      base_table_options: {},
      search: "",
      original_fields: {},
      response_fields: {},
      search_fields: [],
      fields: [],
      tags: [],
      models: [],
      selected_model: "",
      documents_limit: 10,
      entitiesList: [],
      show_from_app: false,
      current: [],
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.page.name + "_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.page.name + "_options");
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_search")) {
      this.search = helpFunctions.getLocalStorageValue(this.page.name + "_search");
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_tags")) {
      this.tags = helpFunctions.getLocalStorageValue(this.page.name + "_tags");
    }
    this.base_table_options = helpFunctions.base_table_options[this.page.name];
    if (this.page.name === "fields") {
      this.models = helpFunctions.models;
      this.selected_model = "Company";
      this.disable_pagination = true;
      this.hide_default_footer = true;
      this.options.itemsPerPage = 50;
    }
  },
  methods: {
    load: lodash.debounce(function () {
      this.run();
    }, 100),
    run() {
      this.records = [];
      this.all_records = [];
      this.loading = true;
      if (this.page.name === "documents") {
        if(this.show_from_app) {
          this.loadDocumentsForSign();
        }
        else {
          for (let i = 0; i < this.documents_limit; i++) {
            this.loadDocuments(i);
          }
        }
      }
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site")
        .then((response) => {
          this.original_fields = response.data.fields;
          if (this.page.name === "documents") {
            this.original_fields.has_assets = "boolean";
          }
          else {
            this.loading = false;
            this.all_records = response.data.data;
          }
          if(this.original_fields.platform_id) {
            this.original_fields.platform_name = "select_single";
            delete this.original_fields.platform_id;
          }
          if(this.original_fields.project_id) {
            this.original_fields.project_name = "select_single";
            delete this.original_fields.project_id;
          }
          if(this.original_fields.punchlist_id) {
            this.original_fields.punchlist_name = "select_single";
            delete this.original_fields.punchlist_id;
          }
          if(this.original_fields.owner_id) {
            this.original_fields.owner_name = "select_single";
            delete this.original_fields.owner_id;
          }
          this.search_fields = [];
          this.search_fields.push({
            text: this.$store.getters.translate('all_fields'),
            value: 'all',
          });
          if (this.search && this.search.length > 2) {
            this.response_fields["search_score"] = "text_field";
          }
          Object.entries(this.original_fields).forEach(([key, value]) => {
            this.response_fields[key] = value;
            if(key !== '' && key !== 'sort_order' && key !== 'updated_at' && key !== 'created_at' && key !== 'tags') {
              this.search_fields.push({
                text: this.$store.getters.translate(key),
                value: key,
              });
            }
          });
          this.fields = this.composeFields(this.response_fields);
          if (this.page.name !== "documents") {
            this.showRecords();
          }
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    manageCreatePermissionDisabledStatus(isDisabled = true) {
      this.isCreatePermissionDisabled = isDisabled;
    },
    fillPermissionFilters() {
      const uniqueFilters = {
        byEntityItem : {},
        byUser : {},
        byRole : {},
      }
      this.all_records.forEach(record => {
        const entity_id = record['specified_for_entity_id'];
        const user_id = record['specified_for_user_id'];
        const role_id = record['specified_for_role_id'];

        if(!uniqueFilters.byEntityItem[entity_id]){
          uniqueFilters.byEntityItem[entity_id] = record['entity_label'];
        }

        if(!uniqueFilters.byUser[user_id]){
          uniqueFilters.byUser[user_id] = record['user_name'];
        }

        if(!uniqueFilters.byRole[role_id]){
          uniqueFilters.byRole[role_id] = record['role_name'];
        }
      })

      this.fillPermissionFiltersHelper(this.filterPermissions.byEntityItem, uniqueFilters.byEntityItem);
      this.fillPermissionFiltersHelper(this.filterPermissions.byUser, uniqueFilters.byUser);
      this.fillPermissionFiltersHelper(this.filterPermissions.byRole, uniqueFilters.byRole);
    },
    fillPermissionFiltersHelper(storeList, dataList) {
      for(let key in dataList) {
        if(Object.hasOwnProperty.call(dataList, key)) {
          storeList.items.push({
              label : this.$store.getters.translate(dataList[key]),
              text : key,
            })
        }
      }
      storeList.isDisabled = false;
      storeList.isLoading = false;
    },
    setShortListOptions(shortListOptions) {
      this.shortListOptions = shortListOptions;
    },
    resetFilters() {
      this.filterPermissions.display.value = 'all';
      this.filterPermissions.byEntityItem.value = 'all';
      this.filterPermissions.byUser.value = 'all';
      this.filterPermissions.byRole.value = 'all';
    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        response.push({
          text: this.$store.getters.translate(field_names[i]),
          align: "start",
          class: 'sticky-header',
          sortable: true,
          value: field_names[i],
        });
      }
      response.push({
        text: this.$store.getters.translate("actions"),
        sortable: false,
        align: "right",
        class: 'sticky-header',
        value: "actions",
      });
      return response;
    },
    showRecords() {
      var sorted_array = [];
      if (this.tags.length > 0) {
        sorted_array = helpFunctions.sortByTagsBase(this.all_records, this.tags);
        if (this.search && this.search.length > 2) {
          sorted_array = helpFunctions.searchInSortedArray(sorted_array, this.response_fields, this.search, this.options.search_column, this.options.exact_search);
        }
      } else if (this.selected_model != "") {
        sorted_array = this.sortByModel();
        if (this.search && this.search.length > 2) {
          sorted_array = helpFunctions.searchInSortedArray(sorted_array, this.response_fields, this.search, this.options.search_column, this.options.exact_search);
        }
      } else if (this.search && this.search.length > 2) {
        sorted_array = helpFunctions.searchInSortedArray(this.all_records, this.response_fields, this.search, this.options.search_column, this.options.exact_search);
      } else {
        sorted_array = this.all_records;
      }

      sorted_array = sorted_array.filter(item => this.canShow(item.id));

      if (this.options.sortBy[0]) {
        sorted_array = helpFunctions.sortByColumn(sorted_array, this.options.sortBy[0], this.options.sortDesc[0]);
      }
      this.records = [];
      this.total_records = sorted_array.length;
      let start = (this.options.page - 1) * this.options.itemsPerPage;
      var end = this.options.page * this.options.itemsPerPage;
      if (end > sorted_array.length) {
        end = sorted_array.length;
      }
      for (let i = start; i < end; i++) {
        this.records.push(sorted_array[i]);
      }
    },
    sortByModel() {
      var response = [];
      for (let i = 0; i < this.all_records.length; i++) {
        if (this.all_records[i].model_type === this.selected_model) {
          response.push(this.all_records[i]);
        }
      }
      return response;
    },
    clearSearch() {
      this.search = "";
      if (this.all_records && this.all_records.length > 0) {
        delete this.response_fields["search_score"];
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.load();
      }
    },
    launchSearch() {
      if (this.all_records && this.all_records.length > 0) {
        if (this.search && this.search.length > 2) {
          this.response_fields = {};
          this.response_fields["search_score"] = "text_field";
          Object.entries(this.original_fields).forEach(([key, value]) => {
            this.response_fields[key] = value;
          });
        } else {
          delete this.response_fields["search_score"];
        }
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.load();
      }
    },
    handleCtrlClick(item) {
      if(this.selected.find((el) => el.id === item.id)) {
        this.selected = this.selected.filter((el) => el.id !== item.id);
      }
      else {
        this.selected.push(item);
      }
    },
    handleShiftClick(item) {
      if(this.selected.length === 1 || this.selected.length === 2) {
        let start = 0;
        let end = this.current.findIndex((el) => el.id === item.id);
        if (this.selected.length === 2) {
          start = this.current.findIndex((el) => el.id === this.selected[0].id);
          if (start > end) {
            let temp = start;
            start = end;
            end = temp;
          }
        }
        for (let i = start; i < end; i++) {
          if(!this.selected.find((el) => el.id === this.current[i].id)) {
            this.selected.push(this.current[i]);
          }
        }
      }
    },
    save() {
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        this.fieldDataChanged(this.records[i]);
      }
      this.$toasted.success(this.$store.getters.translate("successfully_saved"));
    },
    endDrag(items) {
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        for (let z = 0; z < items.length; z++) {
          if (temp_records[i].id === items[z].id && temp_records[i].sort_order !== z + 1) {
            this.records[i].sort_order = z + 1;
            //NO NEED TO SAVE CHANGES IMMEDIATELY
            //this.fieldDataChanged(this.records[i]);
          }
        }
      }
      //this.$toasted.success(this.$store.getters.translate("successfully_saved"));
    },
    fieldDataChanged(field) {
      this.loading = true;
      this.$http
        .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + field.id, field)
        .then(() => {
            this.loading = false;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    bulkArchive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          let result = {
            ids: this.$lodash.map(this.selected, "id"),
            model: this.page.model,
          };
          this.$http
            .post(this.$store.getters.appUrl + "v2/bulk/remove", result)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("successfully_archive"));
              this.selected = [];
              this.load();
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      });
    },
    async loadDocuments(limit) {
      let start = limit * 10000;
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site" + "&start=" + start)
        .then((response) => {
          if (response.data.data) {
            this.all_records = [...this.all_records, ...response.data.data];
          }
          this.documents_limit--;
          if (this.documents_limit == 0) {
            this.loading = false;
            this.documents_limit = 10;
            if(this.$route.query.id) {
              this.$emit('edit', {id: this.$route.query.id});
            }
            this.showRecords();
          }
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    async loadDocumentsForSign() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site" + "&app_docs=true")
          .then((response) => {
            this.loading = false;
            this.all_records = response.data.data;
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.deleteAction(id);
        }
      });
    },
    async deleteAction(id) {
      this.loading = true;
      this.$http
        .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
        .then(() => {
          this.loading = false;
          this.load();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    makeCopy(item) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          item.model = this.page.model;
          this.$http
            .post(this.$store.getters.appUrl + "v2/bulk/copy", item)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.selected = [];
              this.load();
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      });
    },
    importData(results) {
      this.$http
        .post(this.$store.getters.appUrl + "v2/bulk/import?model=" + this.page.model, { results: results })
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("success"));
          this.load();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    viewReport(item) {
      this.$refs.base_report.load(item);
    },
    showPermissionManager(record) {
      this.$refs.custom_permission_manager.managerOptions = {
        entityId: record.id,
        entityModel: this.page.model,
        disabled: [
          'entityItems',
          'entities',
        ]
      }

      this.$refs.custom_permission_manager.page.title = this.$store.getters.translate("manage_custom_permissions");
      this.$refs.custom_permission_manager.show = true;
    },
    editPermissionManager(item) {
      this.$refs.custom_permission_manager.reset(null, true);

      this.selected_record = {
        assoc: {
          custom_permission_action_id: 'action',
          specified_for_entity_id: 'entity_id',
          entity: 'entity',
          higher_order: 'higher_order',
          id: 'id',
          specified_for_role_id: 'role_id',
          status: 'status',
          specified_for_user_id: 'user_id',
        },
        fieldOptions: {
          disabled:  []
        },
        record: item,
        isManagerModal : true,
      };

      this.$refs.custom_permission_manager.page.title = this.$store.getters.translate("edit_custom_permission");
    },
    canCreate(id = 0) {
      return this.$can("create", this.$createEntity(this.page.model, {id}));
    },
    canShow(id = 0) {
      return this.$can("show", this.$createEntity(this.page.model, {id}));
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
    canCreatePermission(id = 0) {
      return this.$can("create", this.$createEntity('CustomPermission', {id}));
    },
    canDelete(id = 0) {
      return this.$can("delete", this.$createEntity(this.page.model, {id}));
    },
  },
  computed: {
    getRecords() {
      return this.records;
    },
  },
  watch: {
    options: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_options", this.options);
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.load();
        }
      },
      deep: true,
    },
    search: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_search", this.search);
        this.options.page = 1;
      },
    },
    tags: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_tags", this.tags);
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.load();
        }
      },
    },
    filterPermissions: {
      handler() {
        this.options.page = 1;
        this.showRecords();
      },
      deep: true,
    },
    selected_model: {
      handler() {
        this.options.page = 1;
        this.showRecords();
      },
    },
    show_from_app: {
      handler() {
        this.load();
      },
    },
  },
};
</script>

<style>
.sticky-header {
  position: sticky;
  top: 65px !important;
  background-color: white;
  z-index: 1;
}
.v-data-table__wrapper {
  overflow: unset !important;
}
.custom-disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>