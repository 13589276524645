<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <v-text-field
                class="mt-5"
                :label="$store.getters.translate('comment')"
                v-model="record.comment"
                rows="7">
              <template #label>{{ $store.getters.translate('comment') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <v-row>
              <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                <v-file-input
                    class="mt-3"
                    :label="$store.getters.translate('files')"
                    placeholder="Drop file to upload"
                    show-size
                    v-model="record.files"
                    multiple/>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["modal_name", "punchlistitem", "punchlist_owner_id"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate('send_review_request')
      },
      loading: false,
      record: {},
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      if(!this.loading) {
        if (!this.record.comment) {
          this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('comment'));
        }
        else {
          this.loading = true;
          const formData = new FormData();

          formData.append("comment", this.record.comment);
          formData.append("punchlistitem_id", this.punchlistitem.id);
          formData.append("punchlistitem_internal_id", this.punchlistitem.internal_id);
          formData.append("punchlist_id", this.punchlistitem.punchlist_id);
          formData.append("punchlist_owner_id", this.punchlist_owner_id);
          formData.append("sender_id", this.$store.state.user.id);

          if (this.record.files) {
            this.record.files.forEach((file) => {
              formData.append("attachment[]", file);
            });
          }

          this.$http
              .post(this.$store.getters.appUrl + "v2/punchlistitems/request-review", formData, { headers: {"Content-Type": "multipart/form-data"} })
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.reset();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      }
    },
    addDropFile(e) {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        this.record.files.push(e.dataTransfer.files[i]);
      }
    },
    reset() {
      this.record = {};
      this.closeModal(this.modal_name);
    },
  },
};
</script>