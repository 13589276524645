<template>
  <v-row class="page-breadcrumb">
    <v-col cols="12" sm="12" class="pa-0">
      <v-card class="px-2 custom-shadow">
        <v-toolbar dense flat class="transparent pa-0" height="40px">
          <v-icon class="pt-3 mr-3">{{ $icons[module_name] }}</v-icon>
          <v-toolbar-title class="pt-3">
            <span> {{ title }}</span>
          </v-toolbar-title>
          <v-toolbar-title class="toobar-extension transparent pb-3" slot="extension">
            <v-breadcrumbs :items.sync="breadcrumbs" class="pa-0">
              <template v-slot:divider v-if="current">
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
          </v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import helpFunctions from "../../plugins/helpFunctions";

export default {
  name: "BaseBreadcrumb",
  props: [
    "module_name",
    "title",
    "current",
  ],
  data() {
    return {
      breadcrumbs: [],
    };
  },
  created() {
    this.breadcrumbs = helpFunctions.getBreadcrumbs(this.module_name, this.current);
  },
  watch: {
    current: {
      handler() {
        this.breadcrumbs = helpFunctions.getBreadcrumbs(this.module_name, this.current);
      },
    },
  },
};
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -20px -25px 27px -25px;
}
</style>