<template>
  <v-expansion-panel v-if="showPanel()">
    <v-expansion-panel-header>
      <v-toolbar class="no-left-padding" dense flat>
        <v-icon class="mr-2">{{ $icons[relation_name] }}</v-icon>
        <v-toolbar-title style="font-size: 13pt" class="ma-0 pa-0">
          {{ $store.getters.translate(relation_name) }}
          <small class="ml-1 text--lighten-3">
            ({{ records.length }})
          </small>
        </v-toolbar-title>
        <!--PUNCHLIST ITEMS FILTER BUTTONS-->
        <template v-if="relation_name === 'punchlistitems'">
          <v-toolbar-title>
            <v-switch class="ml-10 mt-7" :label="$store.getters.translate('hide_inactive')" v-model="options.hide_inactive" @click.stop=""/>
          </v-toolbar-title>
          <v-toolbar-title>
            <v-switch class="ml-10 mt-7" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed" @click.stop=""/>
          </v-toolbar-title>
        </template>
        <v-spacer/>
        <!--EXCEL EXPORT-->
        <download-excel v-if="records.length > 0 && relation_name !== 'punchlistitems'" class="v-btn v-btn--text theme--light v-size--small" :data="records" :name="relation_name + '.xls'">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon small class="ml-2" color="primary">mdi-file-excel</v-icon>
        </download-excel>
        <v-divider class="mr-2 ml-2" vertical/>
        <!--LINK-->
        <template v-if="base_table_includable_options.link && selected.length == 0 && canEditBaseModel">
          <base-link
            :label="$store.getters.translate('link') + ' ' + $store.getters.translate(relation_name)"
            @refresh="$emit('refresh', true)"
            @change="linkOrCreateRecord"
            :module_name="relation_name"
            :excluded_ids="$lodash.map(records, 'id')">
          </base-link>
          <v-divider class="mr-2 ml-2" vertical/>
        </template>
        <!--DELETE-->
        <template v-if="selected.length > 0 && canDelete()">
          <v-btn color="red" @click.stop="bulkArchiveOrDetach" text small>
            <span v-if="base_table_includable_options.detachable">{{ $store.getters.translate("detach") }}</span>
            <span v-else>{{ $store.getters.translate("delete") }}</span>
            <v-icon color="red" class="mr-2">mdi-link-variant</v-icon>
            {{ selected.length }}
          </v-btn>
          <v-divider class="mr-2 ml-2" vertical/>
        </template>
        <!--DOWNLOAD DOCUMENTS-->
        <template v-if="relation_name === 'documents' && selected.length > 0">
          <v-btn @click.stop="$emit('download', selected);" text small color="primary">
            {{ $store.getters.translate("download") }}
          </v-btn>
          <v-divider class="mr-2 ml-2" vertical/>
        </template>
        <!--CREATE-->
        <template v-if="base_table_includable_options.create && canCreate()">
          <v-btn @click.stop="$emit('create', true);" text small color="primary">
            {{ $store.getters.translate("create") }}
            <v-icon small class="ml-2">mdi-plus</v-icon>
          </v-btn>
          <v-divider class="mr-2 ml-2" vertical/>
        </template>
      </v-toolbar>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        :headers="headers"
        :items="getRecords"
        :loading="loading"
        selectable-key="id"
        :show-select="!base_table_includable_options.no_select"
        v-model="selected"
        :options.sync="options"
        :footer-props="footer_props"
        :custom-sort="customSort"
        :search="search"
        @current-items="current = $event">
        <template v-slot:top>
          <v-text-field
            v-if="base_table_includable_options.show_search"
            v-model="search"
            @click:clear="clearSearch"
            :label="$store.getters.translate('search')"
            clearable
            outlined
            dense
            append-icon="mdi-magnify">
          </v-text-field>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.id" @click.ctrl="handleCtrlClick(item)">
            <td v-if="!base_table_includable_options.no_select">
              <v-checkbox v-model="selected" :value="item" style="margin-top: 4px; height: 32px" @click.shift="handleShiftClick(item)"/>
            </td>
            <td v-for="(key, index) in Object.keys(response_fields)" :key="index" @click.exact="$emit('edit', item, $models[relation_name].toLowerCase())">
              <!--CHECKBOXES/BOOLEAN VALUES-->
              <template v-if="response_fields[key] === 'boolean'">
                <!--CUSTOM FIX FOR PUNCHLIST ITEMS-->
                <template v-if="relation_name === 'punchlistitems'">
                  <!--CUSTOM FIX TO REVERT OPEN/CLOSED STATUS-->
                  <template v-if="key === 'status' && (item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true)">Closed</template>
                  <template v-else-if="key === 'status'">Open</template>
                  <template v-else-if="key === 'technical_authority_required' && (item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true)">Yes</template>
                  <template v-else-if="key === 'technical_authority_required'">No</template>
                  <template v-else-if="key === 'active' && (item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true)">Accepted</template>
                  <template v-else-if="key === 'active'">Draft</template>
                  <template v-else-if="key === 'rejected' && (item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true)">Yes</template>
                  <template v-else-if="key === 'rejected'">No</template>
                  <template v-else-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                  <template v-else>0</template>
                </template>
                <!--OTHER MODELS-->
                <template v-else>
                  <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                  <template v-else>0</template>
                </template>
              </template>
              <!--DATES-->
              <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
              </template>
              <!--DROPDOWNS-->
              <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                <template v-if="Array.isArray(item[key])">
                  <template v-if="item[key].length > 1">
                    <v-chip style="height: auto;" class="mr-2" x-small v-for="elem in item[key]" :key="elem">
                      {{ elem }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                  </template>
                </template>
                <template v-else>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                </template>
              </template>
              <!--LINKS-->
              <template v-else-if="response_fields[key] === 'link'">
                <a>
                  <v-avatar v-if="key === 'name' && item.person" class="mr-2" size="20">
                    <v-img style="border: 1px" :src="item.person.avatar ? item.person.avatar : '../../../assets/images/no-user.jpg'"/>
                  </v-avatar>
                  {{ $lodash.truncate(item[key], { length: 50 }) }}
                </a>
              </template>
              <!--ID FIELDS-->
              <template v-else-if="key === 'id' || key === 'internal_id'">
                <a>{{ item[key] }}</a>
              </template>
              <!--TEXT FIELDS-->
              <template v-else>
                {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
              </template>
            </td>
            <!--ACTION BUTTONS-->
            <td v-if="relation_name !== 'equipment'" style="text-align: right">
              <v-icon v-if="canEdit(item.id || 0)" @click="$emit('edit', item, $models[relation_name].toLowerCase())" color="secondary" small class="mr-2">mdi-pencil</v-icon>
              <v-btn v-if="+item.id !== 0 && canCreatePermission()" @click="showPermissionManager(item)" icon dark small color="secondary" :class="[isCreatePermissionDisabled?'custom-disabled' : '']">
                <v-icon small>mdi-lock-outline</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="records.length === 0">
            <td class="text-center grey--text" :colspan="headers.length + 1">
              <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
              <span v-else>{{ $store.getters.translate("loading") }}</span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <manage-by-roles :ref="'custom_permission_manager_' + $models[relation_name].toLowerCase()" :selected_record="selected_record" @disableManaging="manageCreatePermissionDisabledStatus" @refresh="$emit('change')"/>
  </v-expansion-panel>
</template>

<script>
import BaseLink from "./BaseLink";
import ManageByRoles from "../../views/permissions/ManageByRoles";
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions.js";

export default {
  components: {
    BaseLink,
    ManageByRoles,
  },
  props: ["page", "parent_module_id", "relation_name"],
  data() {
    return {
      isCreatePermissionDisabled: false,
      selected_record: null,
      loading: false,
      headers: [],
      selected: [],
      records: [],
      search: "",
      options: {},
      footer_props: {
        itemsPerPageOptions: [15, 25, 50, 75, 100, 125, 150, 175, 200, 250, 300],
        rowsPerPageText: "Rows per page:",
      },
      base_table_includable_options: {},
      response_fields: {},
      current: [],
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.relation_name + "_includable_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.relation_name + "_includable_options");
    }
    this.base_table_includable_options = helpFunctions.base_table_includable_options[this.page.name][this.relation_name];
    if(this.relation_name === 'equipment') {
      this.headers = helpFunctions.project_equipment_headers;
      this.response_fields = helpFunctions.project_equipment_response_fields;
    }
    else {
      this.getFields(this.$models[this.relation_name].toLowerCase());
    }
  },
  methods: {
    showPermissionManager(record) {
      let ref = 'custom_permission_manager_' + this.$models[this.relation_name].toLowerCase();

      this.$refs[ref].managerOptions = {
        entityId: record.id,
        entityModel: this.$models[this.relation_name].toLowerCase(),
        disabled: [
          'entityItems',
          'entities',
        ]
      }

      this.$refs[ref].page.title = this.$store.getters.translate("manage_custom_permissions");
      this.$refs[ref].show = true;
    },
    canCreatePermission(id = 0) {
      return this.$can("create", this.$createEntity('CustomPermission', {id}));
    },
    manageCreatePermissionDisabledStatus(isDisabled = true) {
      this.isCreatePermissionDisabled = isDisabled;
    },
    bulkArchiveOrDetach() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          var result = {
            model_id: this.parent_module_id,
            relation_name: this.$relations[this.relation_name] ? this.$relations[this.relation_name] : this.relation_name,
            ids: this.$lodash.map(this.selected, "id"),
          };
          if(this.page.model === 'employee' && (this.relation_name === 'roles' || this.relation_name === 'tokens')) {
            result.model = 'User';
          }
          else {
            result.model = this.page.model;
          }
          this.$http
            .post(this.$store.getters.appUrl + "v2/bulk/remove", result)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.selected = [];
              this.$emit("change");
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      });
    },
    linkOrCreateRecord(objects) {
      this.loading = true;
      var result = {
        attach: this.$lodash.map(objects, "id"),
        model_id: this.parent_module_id,
        relation_name: this.$relations[this.relation_name] ? this.$relations[this.relation_name] : this.relation_name,
        objects: objects,
      };
      if(this.page.model === 'employee' && (this.relation_name === 'roles' || this.relation_name === 'tokens')) {
        result.model = 'User';
      }
      else {
        result.model = this.page.model;
      }
      this.$http
        .post(this.$store.getters.appUrl + "v2/bulk/attach", result)
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("success"));
          this.$emit("change");
        }).catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    clearSearch() {
      this.search = "";
    },
    canCreate() {
      return this.$can("create", this.$createEntity(this.$models[this.relation_name].toLowerCase(), {id: 0}));
    },
    canEditBaseModel() {
      return this.$can("edit", this.$createEntity(this.page.model, {id: 0}));
    },
    customSort(items, index, isDesc) {
      let field = index[0];
      let desc = isDesc[0];
      let result = this.$lodash.sortBy(items, field);
      if (field === "date_of_birth") {
        result = result.sort((a, b) => {
          a = moment(a["date_of_birth"], "DD-MM-YYYY").toDate();
          b = moment(b["date_of_birth"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (field === "date_punched") {
        result = result.sort((a, b) => {
          a = moment(a["date_punched"], "DD-MM-YYYY").toDate();
          b = moment(b["date_punched"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (field === "date_cleared") {
        result = result.sort((a, b) => {
          a = moment(a["date_cleared"], "DD-MM-YYYY").toDate();
          b = moment(b["date_cleared"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (desc) {
        return result.reverse();
      }
      return result;
    },
    handleCtrlClick(item) {
      if(this.selected.find((el) => el.id === item.id)) {
        this.selected = this.selected.filter((el) => el.id !== item.id);
      }
      else {
        this.selected.push(item);
      }
    },
    handleShiftClick(item) {
      if(this.selected.length === 1 || this.selected.length === 2) {
        let start = 0;
        let end = this.current.findIndex((el) => el.id === item.id);
        if (this.selected.length === 2) {
          start = this.current.findIndex((el) => el.id === this.selected[0].id);
          if (start > end) {
            let temp = start;
            start = end;
            end = temp;
          }
        }
        for (let i = start; i < end; i++) {
          if(!this.selected.find((el) => el.id === this.current[i].id)) {
            this.selected.push(this.current[i]);
          }
        }
      }
    },
    canDelete(id = 0) {
      return this.$can("delete", this.$createEntity(this.$models[this.relation_name].toLowerCase(), {id}));
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.$models[this.relation_name].toLowerCase(), {id}));
    },
    showPanel(id = 0) {
      return this.$can("show", this.$createEntity(this.$models[this.relation_name].toLowerCase(), {id}));
    },
    async getFields(module_name) {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_in_search=true&model_type=" + module_name)
          .then((response) => {
            this.headers = [];
            response.data.data.forEach((item) => {
              if(item.name === "punched_by") {
                item.name = "punched_by_name";
              }
              else if(item.name === "follow_up_by") {
                item.name = "follow_up_by_name";
              }
              else if(item.name === "technical_authority_by") {
                item.name = "technical_authority_by_name";
              }
              this.headers.push({
                text: this.$store.getters.translate(item.name),
                align: "start",
                class: 'sticky-header',
                sortable: true,
                value: item.name,
              });
              this.response_fields[item.name] = item.field_type;
            });
            this.headers.push({
              text: this.$store.getters.translate("actions"),
              sortable: false,
              align: "right",
              class: 'sticky-header',
              value: "actions",
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  computed: {
    getRecords() {
      if(this.relation_name === 'punchlistitems') {
        if(this.options.hide_inactive && this.options.hide_closed) {
          return this.records.filter((record) => record.active && !record.status);
        }
        else if(this.options.hide_inactive) {
          return this.records.filter((record) => record.active);
        }
        else if(this.options.hide_closed) {
          return this.records.filter((record) => !record.status);
        }
        else {
          return this.records;
        }
      }
      else {
        return this.records;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.selected = [];
        helpFunctions.setLocalStorageValue(this.relation_name + "_includable_options", this.options);
      },
      deep: true,
    },
  },
};
</script>

<style>
.sticky-header {
  position: sticky;
  top: 65px !important;
  background-color: white;
  z-index: 1;
}
.v-data-table__wrapper {
  overflow: unset !important;
}
.custom-disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style> 