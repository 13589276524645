<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :loading="loading"
        @click.prevent="go()"
        v-bind="attrs"
        v-on="on"
        text
        small
        color="primary">
        {{ $store.getters.translate("import") }}
        <v-icon small class="ml-2">mdi-import</v-icon>
      </v-btn>
    </template>
  </v-tooltip>
</template>

<script>
import FlatfileImporter from "flatfile-csv-importer";

export default {
  props: ["title"],
  data() {
    return {
      loading: false,
      importer: null,
      config: {
        license: process.env.VUE_APP_FLATFILE_LICENSE,
        config: {
          fields: [],
          type: "data",
          allowInvalidSubmit: false,
          managed: true,
          allowCustom: true,
          disableManualInput: false,
          styleOverrides: {
            borderRadius: "4px", // Flatfile wrapper, modals, & buttons**
            primaryButtonColor: "#7BA5B6", // Primary buttons*
            uploadButtonColor: "#7BA5B6", // Upload buttons*
            linkColor: "#7BA5B6", // Links*
            linkAltColor: "#4a90e2", // :hover, :active, & :focus states of links*
            primaryTextColor: "#7BA5B6", // Main text color, background-color of certain buttons*
            secondaryTextColor: "#9daab6", // 'Dimmed' text*
            errorColor: "#d0011b", // Invalid and error indicator borders, icons, and text*
            successColor: "#60b700", // Success text, buttons, icons, toggles, required indicators*
            warningColor: "#f6a623", // Warning text and icons*
            borderColor: "#e8e8e8", // Flatfile wrapper, modals, and some buttons*
            fontFamily: '"Poppins", sans-serif', // Main font***
          },
        },
      },
      results: [],
      fields: [],
      countries: [],
      nationalities: [],
    };
  },
  methods: {
    go() {
      this.loading = true;
      this.getFields();
    },
    load() {
      let parsed_fields = [];
      this.fields.forEach((field) => {
        let validators;
        let type;
        let options;
        if (field.mandatory || field.name === 'name' || field.name === 'last_name' || field.name === 'first_name') {
          validators = [
            {
              validate: "required",
            },
          ];
        }
        if (field.field_type === "boolean") {
          type = "checkbox";
        }
        if (field.field_type === "select_single" || field.field_type === "select_multiple") {
          if(field.name === 'country_of_birth_id') {
            type = "select";
            options = this.countries;
          }
          else if(field.name === 'nationality_id') {
            type = "select";
            options = this.nationalities;
          }
          else if (field.available_options) {
            let current_options = [];
            field.available_options.forEach((option) => {
              current_options.push({
                value: option,
                label: this.$store.getters.translate(option),
              });
            });
            type = "select";
            options = current_options;
          }
        }
        parsed_fields.push({
          key: field.name,
          label: this.$store.getters.translate(field.name),
          alternates: [],
          validators,
          type,
          options,
        });
      });
      this.config.config.fields = parsed_fields;
      // this.config.config.type = this.$store.getters.translate(this.title);
      FlatfileImporter.setVersion(2);
      this.importer = new FlatfileImporter(this.config.license, this.config.config);
      //   this.importer.registerRecordHook((record, index) => {
      //     let out = {};
      //     if (record.equipment_type) {
      //       out.equipment_type = {
      //         value: record.equipment_type.toLowerCase()
      //       };
      //     }
      //     return out;
      //   });
      this.launch();
    },
    launch() {
      const $v = this;
      this.importer.setCustomer({
        userId: "perscriptum",
        email: "dummy@pernexus.org",
      });
      this.importer
        .requestDataFromUser()
        .then(function (results) {
          $v.results = JSON.stringify(results.validData, null, 2);
          $v.importer.displaySuccess("Success!");
          $v.import(results.validData);
        })
        .catch(function (error) {
          console.info(error || "window close");
          $v.loading = false;
        });
    },
    import(results) {
      this.$emit("result", results);
      this.loading = false;
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?import=" + this.$models[this.title])
          .then((response) => {
            this.fields = [];
            var getCountryNationalityLaunched = false;
            response.data.forEach((field) => {
              if(field.name !== 'id' && field.name !== 'tags' && field.name !== 'updated_at' && field.name !== 'created_at') {
                if((this.$models[this.title]  === 'Contact' || this.$models[this.title]  === 'Employee') && field.name === 'name') {
                  var names = ["first_name","initials","last_name","insertion"];
                  for (let i = 0; i < names.length; i++) {
                    var current_field = Object.assign({},field);
                    current_field.name = names[i];
                    this.fields.push(current_field);
                  }
                }
                else {
                  this.fields.push(field);
                }
              }
              if(field.name === 'country_of_birth_id' || field.name === 'nationality_id') {
                getCountryNationalityLaunched = true;
              }
            });
            if(getCountryNationalityLaunched) {
              this.getCountryNationality();
            }
            else {
              this.loading = false;
              this.load();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    getCountryNationality() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/countries")
          .then((response) => {
            this.loading = false;
            this.countries = [];
            response.data.country.forEach((option) => {
              this.countries.push({
                value: option.country_id,
                label: option.country_name,
              });
            });
            this.nationalities = [];
            response.data.nationality.forEach((option) => {
              this.nationalities.push({
                value: option.nationality_id,
                label: option.nationality_name,
              });
            });
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
  },
};
</script>