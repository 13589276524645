<template>
  <v-row>
    <v-col>
      <v-form ref="form" v-model="valid">
        <base-row
          v-for="(row, index) in updated_emailaddresses"
          :key="index"
          :label="$store.getters.translate('emailaddress')"
          :value="row.emailaddress"
          :deletable="true"
          @delete="deleteEmailaddress(row)"/>
        <v-text-field
          v-model="new_emailaddress.emailaddress"
          validate-on-blur
          @click:append-outer="add"
          append-outer-icon="mdi-content-save"
          :rules="emailRules"
          required
          :placeholder="$store.getters.translate('email')"
          v-on:keydown.enter.prevent="add"
          @blur="add"/>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["value","is_submit"],
  data() {
    return {
      updated_emailaddresses: [],
      loading: false,
      new_emailaddress: {
        emailaddress: "",
        active: 0,
      },
      valid: true,
      does_not_exist: true,
      emailRules: [
        (v) => !!v || this.$store.getters.translate("please_submit_valid_email"),
        (v) => /.+@.+\..+/.test(v) || this.$store.getters.translate("please_submit_valid_email"),
        (v) => this.check(v) || this.$store.getters.translate("please_submit_valid_email"),
      ],
    };
  },
  mounted() {
    this.updated_emailaddresses = this.value ? this.value : [];
  },
  methods: {
    update() {
      this.$emit("input", this.updated_emailaddresses);
      this.$emit("change");
    },
    add() {
      if (!this.loading) {
        this.check();
        if (this.valid == true && this.new_emailaddress.emailaddress != "") {
          this.updated_emailaddresses.push(this.new_emailaddress);
          this.new_emailaddress = {
            emailaddress: "",
            active: 0,
          };
          this.update();
        }
      }
    },

    check(value) {
      var validator = require("email-validator");
      let check = validator.validate(value);
      if (!check) {
        return false;
      }
      return true;
    },
    exists() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/emailaddresses/?search=" + this.new_emailaddress.emailaddress)
        .then((response) => {
          let result = false;
          if (response.data.length == 0) {
            result = true;
          }
          this.loading = false;
          this.does_not_exist = result;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },

    deleteEmailaddress(row) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
            if (res) {
                this.updated_emailaddresses = this.updated_emailaddresses.filter((record) => record.emailaddress != row.emailaddress);
                if (this.updated_emailaddresses.length == 0) {
                    this.$emit('changes_in_emailaddress');
                }
                this.update();
                if (row.id) {
                  let queryStr = '?';
                    if (this.$route.meta.model) {
                       queryStr += "?model=" + this.$route.meta.model + "&";
                    }
                    queryStr += "model_id=" + this.$route.params.id;
                    queryStr = queryStr.replaceAll(/\?\?/, '?');
                  this.$http
                    .delete(this.$store.getters.appUrl + "v2/emailaddresses/" + row.id + queryStr)
                    .then(() => {
                      this.loading = false;
                      this.$toasted.success(this.$store.getters.translate("success"));
                    }).catch((error) => {
                      if (this.$store.getters.isLoggedIn) {
                        this.$toasted.error(error);
                      }
                      this.loading = false;
                    });
                }
            }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  watch: {
    value: {
      handler() {
        this.updated_emailaddresses = this.value;
      },
    },
    is_submit:function() {
        this.new_emailaddress.emailaddress = ''
    }
  },
};
</script>