<template>
  <div>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="modal_name">
      <v-card :disabled="loading" :loading="loading">
        <BaseModal>
          <v-toolbar slot="header" class="draggable_selector" dark color="primary" dense flat>
            <v-btn icon @click="reset()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate(module_name) }}</v-toolbar-title>
            <v-spacer/>
            <v-btn text small @click="save()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text>
              <v-text-field
                  v-model="search"
                  v-debounce:250ms.lock="launchSearch"
                  @click:clear="clearSearch"
                  :label="$store.getters.translate('search')"
                  single-line
                  hide-details
                  clearable
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  class="mb-3 mt-3"/>
              <v-autocomplete
                  v-if="!hide_platforms"
                  :label="$store.getters.translate('select_platforms')"
                  v-model="selected_platforms"
                  :items="platforms"
                  chips
                  deletable-chips
                  small-chips
                  multiple
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  outlined
                  dense
                  hide-details
                  clearable
                  class="mb-5"/>
              <base-tags v-model="tags" :isDisabled="module_name === 'punchlistitems'" modal_name="assets"/>
              <template v-if="action === 'copy'">
                <v-switch v-model="copy_tags" :label="$store.getters.translate('copy_tags')"/>
                <v-switch v-model="copy_documents" :label="$store.getters.translate('copy_documents')"/>
              </template>
              <v-data-table
                  v-if="module_name === 'punchlistitems'"
                  :headers="asset_headers"
                  :items="assets_array"
                  :items-per-page="5"
                  class="mt-5"
                  :show-select="false"
                  :loading="loading">
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td>
                        <v-checkbox v-model="selected_assets" :value="item.id" color="#f0b216" style="margin-top: 4px; height: 32px"/>
                      </td>
                      <td>
                        <template>
                          {{ $lodash.truncate($store.getters.translate(item['name']), { length: 30 }) }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              <v-treeview
                  v-else
                  :items="assets"
                  item-key="id"
                  item-text="name"
                  :open.sync="open_assets"
                  :active.sync="active_assets"
                  activatable
                  color="success"
                  dense
                  transition
                  :key="key"
                  hoverable
                  selection-type="independent"
                  v-model="selected_assets"
                  selectable/>
            </v-card-text>
          </div>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import lodash from "lodash";
import helpFunctions from "../../plugins/helpFunctions.js";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  props: [
    "module_name",
    "current_assets",
    "modal_name",
    "action",
    "hide_platforms"
  ],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      all_assets: [],
      all_assets_names: {},
      assets_array: [],
      assets: [],
      copy_tags: false,
      copy_documents: false,
      all_selected_assets: null,
      selected_assets: null,
      active_assets: null,
      open_assets: null,
      platforms: [],
      selected_platforms: [],
      key: 0,
      loading: false,
      assets_limit: 10,
      id: null,
      search: "",
      reg_exp: "",
      tags: null,
      asset_headers: [
        { text: " ", value: " ", width: '10%' },
        {
          text: this.$store.getters.translate("name"),
          align: "start",
          sortable: true,
          value: "name",
          width: '90%'
        },
      ],
    };
  },
  created() {
    this.getPlatforms();
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.openModal(this.modal_name);
      this.all_assets = [];
      if (this.selected_platforms.length > 0) {
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => {
          this.loadSelectedPlatforms();
        }, 1000);
      } else if (this.loading === false) {
        clearTimeout(this._timerId);
        this.allPlatforms();
        for (let i = 0; i < this.assets_limit; i++) {
          this.platformNames(i);
        }
      }
    },
    async getPlatforms() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/assets?root=true")
          .then((response) => {
            this.platforms = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async allPlatforms() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/assets")
          .then((response) => {
            this.all_assets = response.data;
            if (this.assets_limit === 0) {
              this.loading = false;
              this.assets_limit = 10;
              this.combineIDsWithNames(this.all_assets);
              this.all_assets_names = {};
              this.all_assets = this.$lodash.sortBy(this.all_assets, "name");
              this.showRecords();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async platformNames(limit) {
      let start = limit * 5000;
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/assets/names", { start: start })
          .then((response) => {
            this.all_assets_names = {
              ...this.all_assets_names,
              ...response.data,
            };
            this.assets_limit--;
            if (this.assets_limit === 0 && this.all_assets.length > 0) {
              this.loading = false;
              this.assets_limit = 10;
              this.combineIDsWithNames(this.all_assets);
              this.all_assets_names = {};
              this.all_assets = this.$lodash.sortBy(this.all_assets, "name");
              this.showRecords();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async loadSelectedPlatforms() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/assets" + "?selected_platforms=" + this.selected_platforms)
          .then((response) => {
            this.loading = false;
            this.all_assets = this.$lodash.sortBy(response.data, "name");
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    clearSearch() {
      this.search = "";
      this.showRecords();
      if(this.all_selected_assets) {
        this.all_selected_assets.forEach(asset => {
          if(!this.selected_assets.includes(asset)) {
            this.selected_assets.push(asset);
          }
        });
      }
      this.all_selected_assets = null;
    },
    launchSearch() {
      if (this.search && this.search.length > 1) {
        if (this.all_selected_assets == null) {
          this.all_selected_assets = lodash.cloneDeep(this.selected_assets);
        } else {
          this.selected_assets.forEach(asset => {
            if (!this.all_selected_assets.includes(asset)) {
              this.all_selected_assets.push(asset);
            }
          });
        }
        this.showRecords();
        if (this.all_selected_assets) {
          this.all_selected_assets.forEach(asset => {
            if (!this.selected_assets.includes(asset)) {
              this.selected_assets.push(asset);
            }
          });
        }
      }
      else if (this.search === '') {
        this.showRecords();
        if(this.all_selected_assets) {
          this.all_selected_assets.forEach(asset => {
            if(!this.selected_assets.includes(asset)) {
              this.selected_assets.push(asset);
            }
          });
        }
        this.all_selected_assets = null;
      }
    },
    combineIDsWithNames(items) {
      items.forEach((item) => {
        item.name = this.all_assets_names[item.id]["name"];
        if (item.children) {
          this.combineIDsWithNames(item.children);
        }
      });
    },
    recursiveEmptinessValidation(assets) {
      return assets
          .filter(asset => !!asset)
          .map(asset => {
            if (asset.children) {
              asset.children = this.recursiveEmptinessValidation(asset.children);
            }
            return asset;
          })
    },
    createAssetsArray(assets) {
      assets.forEach(asset => {
        /*this.assets_array.push({
          id: asset.id,
          name: asset.name
        });*/
        if(asset.children) {
          this.createAssetsArray(asset.children);
        }
        else {
          this.assets_array.push({
            id: asset.id,
            name: asset.name
          });
        }
      });
    },
    showRecords() {
      if (this.all_assets.length > 0) {
        this.loading = true;
        this.assets = lodash.cloneDeep(this.all_assets);
        if (this.tags && this.tags.length > 0) {
          this.assets = helpFunctions.sortByTags(this.assets, this.tags);
        }
        if (this.search != "") {
          let response = helpFunctions.customSearch(this.assets, this.search);
          this.assets = response[0];
          this.open_assets = response[1];
        }
        this.assets = this.recursiveEmptinessValidation(this.assets);
        this.assets = helpFunctions.customSorting(this.assets);
        this.assets_array = [];
        this.createAssetsArray(this.assets);
        if(this.selected_assets == null) {
          this.selected_assets = this.current_assets;
        }
        this.loading = false;
      }
    },
    save() {
      if(this.action === 'copy') {
        this.$emit("refresh", { ids: this.selected_assets, copy_tags: this.copy_tags, copy_documents: this.copy_documents });
      }
      else {
        if(this.all_selected_assets) {
          this.all_selected_assets.forEach(asset => {
            if(!this.selected_assets.includes(asset)) {
              this.selected_assets.push(asset);
            }
          });
        }
        this.all_selected_assets = null;
        this.$emit("refresh", this.selected_assets);
      }
      this.reset();
    },
    reset() {
      this.all_assets = [];
      this.all_assets_names = {};
      this.assets = [];
      this.copy_tags = false;
      this.copy_documents = false;
      this.all_selected_assets = null;
      this.selected_assets = null;
      this.active_assets = null;
      this.open_assets = null;
      this.platforms = [];
      this.key = 0;
      this.loading = false;
      this.assets_limit = 10;
      this.search = "";
      this.reg_exp = "";
      this.closeModal(this.modal_name);
    },
  },
  watch: {
    tags: {
      handler() {
        this.showRecords();
      },
    },
    selected_platforms: {
      handler() {
        this.load();
      },
    },
    selected_assets: {
      handler() {
        if(this.module_name === 'documents' || this.module_name === 'punchlistitems' || this.action === "copy") {
          //ignore document create/edit pages due to possibility to attach document to multiple assets
        }
        else if(this.selected_assets.length > 1) {
          this.$toasted.error(this.$store.getters.translate("only_one_asset_selection"));
          this.selected_assets.pop();
        }
      },
    },
  },
};
</script>