var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{attrs:{"name":_vm.name,"draggable":!_vm.fullscreen ? '.draggable_selector' : false,"resizable":true,"adaptive":true,"cancelable":true,"height":"auto","width":_vm.calcWidth,"classes":['content-wrapper' ].concat( _vm.classes, _vm.fullscreen ? ['left-0'] : []),"overlayTransition":"dialog-bottom-transition"}},[_c('div',{style:([
      Object.assign({}, _vm.width ? {
          'width' : _vm.width
        } : {},
        _vm.height ? {
          'height' : _vm.height
        } : {},
        _vm.maxHeight ? {
          'max-height' : _vm.maxHeight
        } : {},
        _vm.maxWidth ? {
          'max-width' : '100%'
        } : {},
        _vm.fullscreen ? {
          'width' : '100vw',
          'max-width' : 'unset',
          'height' : '100vh',
          'max-height' : 'unset',
        } : {})
    ])},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }