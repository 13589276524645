<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view />
    <modals-container/>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  created: function () {
    this.$vuetify.lang.current = this.$store.getters.getLanguage;
  },
};
</script>
<style>
@import './assets/css/main.css';
</style>