export default {
    addresses: 'Address',
    assets: 'Asset',
    companies: 'Company',
    contacts: 'Contact',
    documents: 'Document',
    fields: 'Field',
    fieldsets: 'Fieldset',
    forms: 'Form',
    reportsections: 'Reportsection',
    emailaddresses: 'Emailaddress',
    employees: 'Employee',
    equipment: 'Equipment',
    notes: 'Note',
    people: 'Person',
    planner: 'Planner',
    platforms: 'Asset',
    projects: 'Project',
    punchlists: 'Punchlist',
    punchlistitems: 'PunchlistItem',
    typesandsubtypes: 'TypesAndSubtype',
    roles: 'Role',
    search: 'Search',
    templates: 'Template',
    tokens: 'Token',
    translations: 'Translation',
    users: 'User',
    workflows: 'Workflow',
    custom_permissions: 'CustomPermission',
    tags: 'Tag',
}