export default {
    // modules
    dashboard: 'mdi-desktop-mac-dashboard',
    projects: 'mdi-account-hard-hat',
    punchlists: 'mdi-format-list-bulleted',
    punchlistitems: 'mdi-format-list-bulleted',
    platforms: 'mdi-file-tree-outline',
    documents: 'mdi-file-document',
    notes: 'mdi-note',
    search: 'mdi-folder-search',
    companies: 'mdi-office-building',
    contacts: 'mdi-contacts-outline',
    templates: 'mdi-file-document-edit',
    translations: 'mdi-translate',
    fields: 'mdi-format-letter-matches',
    tags: 'mdi-tag',
    typesandsubtypes: 'mdi-file-document-edit',
    employees: 'mdi-account',
    forms: 'mdi-file-table-outline',
    equipments: 'mdi-ticket-confirmation-outline',
    fieldsets: 'mdi-view-list-outline',
    reportsections: 'mdi-view-list-outline',
    importassets: 'mdi-upload',
    roles: 'mdi-account-group',
    workflows: 'mdi-transit-connection-variant',
    settings: 'mdi-cogs',
    created: 'mdi-plus',
    result_set: 'mdi-content-save-move-outline',
    users: 'mdi-account',
    profile: 'mdi-account',
    people: 'mdi-human-male-female',
    tokens: 'mdi-account-key',
    permissions: 'mdi-security',
    planner: 'mdi-calendar',
    documentation: 'mdi-bookshelf',
}