import store from './store'

export default [
    {
        name: 'show_project',
        label: store.getters.translate('show_project'),
        guard_name: 'sanctum',
        model: 'project'
    },
    {
        name: 'create_project',
        label: store.getters.translate('create_project'),
        guard_name: 'sanctum',
        model: 'project'
    },
    {
        name: 'edit_project',
        label: store.getters.translate('edit_project'),
        guard_name: 'sanctum',
        model: 'project'
    },
    {
        name: 'delete_project',
        label: store.getters.translate('delete_project'),
        guard_name: 'sanctum',
        model: 'project'
    },
    {
        name: 'show_punchlist',
        label: store.getters.translate('show_punchlist'),
        guard_name: 'sanctum',
        model: 'punchlist'
    },
    {
        name: 'create_punchlist',
        label: store.getters.translate('create_punchlist'),
        guard_name: 'sanctum',
        model: 'punchlist'
    },
    {
        name: 'edit_punchlist',
        label: store.getters.translate('edit_punchlist'),
        guard_name: 'sanctum',
        model: 'punchlist'
    },
    {
        name: 'delete_punchlist',
        label: store.getters.translate('delete_punchlist'),
        guard_name: 'sanctum',
        model: 'punchlist'
    },
    {
        name: 'show_punchlistitem',
        label: store.getters.translate('show_punchlistitem'),
        guard_name: 'sanctum',
        model: 'punchlistitem'
    },
    {
        name: 'create_punchlistitem',
        label: store.getters.translate('create_punchlistitem'),
        guard_name: 'sanctum',
        model: 'punchlistitem'
    },
    {
        name: 'edit_punchlistitem',
        label: store.getters.translate('edit_punchlistitem'),
        guard_name: 'sanctum',
        model: 'punchlistitem'
    },
    {
        name: 'delete_punchlistitem',
        label: store.getters.translate('delete_punchlistitem'),
        guard_name: 'sanctum',
        model: 'punchlistitem'
    },
    {
        name: 'show_platform',
        label: store.getters.translate('show_platform'),
        guard_name: 'sanctum',
        model: 'platform'
    },
    {
        name: 'create_platform',
        label: store.getters.translate('create_platform'),
        guard_name: 'sanctum',
        model: 'platform'
    },
    {
        name: 'edit_platform',
        label: store.getters.translate('edit_platform'),
        guard_name: 'sanctum',
        model: 'platform'
    },
    {
        name: 'delete_platform',
        label: store.getters.translate('delete_platform'),
        guard_name: 'sanctum',
        model: 'platform'
    },
    {
        name: 'show_document',
        label: store.getters.translate('show_document'),
        guard_name: 'sanctum',
        model: 'document'
    },
    {
        name: 'create_document',
        label: store.getters.translate('create_document'),
        guard_name: 'sanctum',
        model: 'document'
    },
    {
        name: 'edit_document',
        label: store.getters.translate('edit_document'),
        guard_name: 'sanctum',
        model: 'document'
    },
    {
        name: 'delete_document',
        label: store.getters.translate('delete_document'),
        guard_name: 'sanctum',
        model: 'document'
    },
    {
        name: 'show_note',
        label: store.getters.translate('show_note'),
        guard_name: 'sanctum',
        model: 'note'
    },
    {
        name: 'create_note',
        label: store.getters.translate('create_note'),
        guard_name: 'sanctum',
        model: 'note'
    },
    {
        name: 'edit_note',
        label: store.getters.translate('edit_note'),
        guard_name: 'sanctum',
        model: 'note'
    },
    {
        name: 'delete_note',
        label: store.getters.translate('delete_note'),
        guard_name: 'sanctum',
        model: 'note'
    },
    {
        name: 'show_company',
        label: store.getters.translate('show_company'),
        guard_name: 'sanctum',
        model: 'company'
    },
    {
        name: 'create_company',
        label: store.getters.translate('create_company'),
        guard_name: 'sanctum',
        model: 'company'
    },
    {
        name: 'edit_company',
        label: store.getters.translate('edit_company'),
        guard_name: 'sanctum',
        model: 'company'
    },
    {
        name: 'delete_company',
        label: store.getters.translate('delete_company'),
        guard_name: 'sanctum',
        model: 'company'
    },
    {
        name: 'show_contact',
        label: store.getters.translate('show_contact'),
        guard_name: 'sanctum',
        model: 'contact'
    },
    {
        name: 'create_contact',
        label: store.getters.translate('create_contact'),
        guard_name: 'sanctum',
        model: 'contact'
    },
    {
        name: 'edit_contact',
        label: store.getters.translate('edit_contact'),
        guard_name: 'sanctum',
        model: 'contact'
    },
    {
        name: 'delete_contact',
        label: store.getters.translate('delete_contact'),
        guard_name: 'sanctum',
        model: 'contact'
    },
    {
        name: 'show_template',
        label: store.getters.translate('show_template'),
        guard_name: 'sanctum',
        model: 'template'
    },
    {
        name: 'create_template',
        label: store.getters.translate('create_template'),
        guard_name: 'sanctum',
        model: 'template'
    },
    {
        name: 'edit_template',
        label: store.getters.translate('edit_template'),
        guard_name: 'sanctum',
        model: 'template'
    },
    {
        name: 'delete_template',
        label: store.getters.translate('delete_template'),
        guard_name: 'sanctum',
        model: 'template'
    },
    {
        name: 'create_translation',
        label: store.getters.translate('create_translation'),
        guard_name: 'sanctum',
        model: ''
    },
    {
        name: 'edit_translation',
        label: store.getters.translate('edit_translation'),
        guard_name: 'sanctum',
        model: 'translation'
    },
    {
        name: 'show_translation',
        label: store.getters.translate('show_translation'),
        guard_name: 'sanctum',
        model: 'translation'
    },
    {
        name: 'delete_translation',
        label: store.getters.translate('delete_translation'),
        guard_name: 'sanctum',
        model: 'translation'
    },
    {
        name: 'show_field',
        label: store.getters.translate('show_field'),
        guard_name: 'sanctum',
        model: 'field'
    },
    {
        name: 'create_field',
        label: store.getters.translate('create_field'),
        guard_name: 'sanctum',
        model: 'field'
    },
    {
        name: 'edit_field',
        label: store.getters.translate('edit_field'),
        guard_name: 'sanctum',
        model: 'field'
    },
    {
        name: 'delete_field',
        label: store.getters.translate('delete_field'),
        guard_name: 'sanctum',
        model: 'field'
    },
    {
        name: 'create_tag',
        label: store.getters.translate('create_tag'),
        guard_name: 'sanctum',
        model: 'tag'
    },
    {
        name: 'edit_tag',
        label: store.getters.translate('edit_tag'),
        guard_name: 'sanctum',
        model: 'tag'
    },
    {
        name: 'show_tag',
        label: store.getters.translate('show_tag'),
        guard_name: 'sanctum',
        model: 'tag'
    },
    {
        name: 'delete_tag',
        label: store.getters.translate('delete_tag'),
        guard_name: 'sanctum',
        model: 'tag'
    },
    {
        name: 'create_employee',
        label: store.getters.translate('create_employee'),
        guard_name: 'sanctum',
        model: 'employee'
    },
    {
        name: 'edit_employee',
        label: store.getters.translate('edit_employee'),
        guard_name: 'sanctum',
        model: 'employee'
    },
    {
        name: 'show_employee',
        label: store.getters.translate('show_employee'),
        guard_name: 'sanctum',
        model: 'employee'
    },
    {
        name: 'delete_employee',
        label: store.getters.translate('delete_employee'),
        guard_name: 'sanctum',
        model: 'employee'
    },
    {
        name: 'create_form',
        label: store.getters.translate('create_form'),
        guard_name: 'sanctum',
        model: 'form'
    },
    {
        name: 'edit_form',
        label: store.getters.translate('edit_form'),
        guard_name: 'sanctum',
        model: 'form'
    },
    {
        name: 'show_form',
        label: store.getters.translate('show_form'),
        guard_name: 'sanctum',
        model: 'form'
    },
    {
        name: 'delete_form',
        label: store.getters.translate('delete_form'),
        guard_name: 'sanctum',
        model: 'form'
    },
    {
        name: 'create_equipment',
        label: store.getters.translate('create_equipment'),
        guard_name: 'sanctum',
        model: 'equipment'
    },
    {
        name: 'edit_equipment',
        label: store.getters.translate('edit_equipment'),
        guard_name: 'sanctum',
        model: 'equipment'
    },
    {
        name: 'show_equipment',
        label: store.getters.translate('show_equipment'),
        guard_name: 'sanctum',
        model: 'equipment'
    },
    {
        name: 'delete_equipment',
        label: store.getters.translate('delete_equipment'),
        guard_name: 'sanctum',
        model: 'equipment'
    },
    {
        name: 'create_fieldset',
        label: store.getters.translate('create_fieldset'),
        guard_name: 'sanctum',
        model: 'fieldset'
    },
    {
        name: 'edit_fieldset',
        label: store.getters.translate('edit_fieldset'),
        guard_name: 'sanctum',
        model: 'fieldset'
    },
    {
        name: 'show_fieldset',
        label: store.getters.translate('show_fieldset'),
        guard_name: 'sanctum',
        model: 'fieldset'
    },
    {
        name: 'delete_fieldset',
        label: store.getters.translate('delete_fieldset'),
        guard_name: 'sanctum',
        model: 'fieldset'
    },
    {
        name: 'create_reportsection',
        label: store.getters.translate('create_reportsection'),
        guard_name: 'sanctum',
        model: 'reportsection'
    },
    {
        name: 'edit_reportsection',
        label: store.getters.translate('edit_reportsection'),
        guard_name: 'sanctum',
        model: 'reportsection'
    },
    {
        name: 'show_reportsection',
        label: store.getters.translate('show_reportsection'),
        guard_name: 'sanctum',
        model: 'reportsection'
    },
    {
        name: 'delete_reportsection',
        label: store.getters.translate('delete_reportsection'),
        guard_name: 'sanctum',
        model: 'reportsection'
    },
    {
        name: 'show_role',
        label: store.getters.translate('show_role'),
        guard_name: 'sanctum',
        model: ''
    },
    {
        name: 'create_role',
        label: store.getters.translate('create_role'),
        guard_name: 'sanctum',
        model: 'role'
    },
    {
        name: 'edit_role',
        label: store.getters.translate('edit_role'),
        guard_name: 'sanctum',
        model: 'role'
    },
    {
        name: 'delete_role',
        label: store.getters.translate('delete_role'),
        guard_name: 'sanctum',
        model: 'role'
    },
    {
        name: 'show_workflow',
        label: store.getters.translate('show_workflow'),
        guard_name: 'sanctum',
        model: ''
    },
    {
        name: 'create_workflow',
        label: store.getters.translate('create_workflow'),
        guard_name: 'sanctum',
        model: 'workflow'
    },
    {
        name: 'edit_workflow',
        label: store.getters.translate('edit_workflow'),
        guard_name: 'sanctum',
        model: 'workflow'
    },
    {
        name: 'delete_workflow',
        label: store.getters.translate('delete_workflow'),
        guard_name: 'sanctum',
        model: 'workflow'
    },
    {
        name: 'show_settings',
        label: store.getters.translate('show_settings'),
        guard_name: 'sanctum',
        model: 'settings'
    },
    {
        name: 'create_settings',
        label: store.getters.translate('create_settings'),
        guard_name: 'sanctum',
        model: 'settings'
    },
    {
        name: 'edit_settings',
        label: store.getters.translate('edit_settings'),
        guard_name: 'sanctum',
        model: 'settings'
    },
    {
        name: 'delete_settings',
        label: store.getters.translate('delete_settings'),
        guard_name: 'sanctum',
        model: 'setting'
    },
    {
        name: 'show_file',
        label: store.getters.translate('show_file'),
        guard_name: 'sanctum',
        model: 'file'
    },
    {
        name: 'create_file',
        label: store.getters.translate('create_file'),
        guard_name: 'sanctum',
        model: 'file'
    },
    {
        name: 'edit_file',
        label: store.getters.translate('edit_file'),
        guard_name: 'sanctum',
        model: 'file'
    },
    {
        name: 'delete_file',
        label: store.getters.translate('delete_file'),
        guard_name: 'sanctum',
        model: 'file'
    },
    {
        name: 'show_user',
        label: store.getters.translate('show_user'),
        guard_name: 'sanctum',
        model: 'user'
    },
    {
        name: 'create_user',
        label: store.getters.translate('create_user'),
        guard_name: 'sanctum',
        model: 'user'
    },
    {
        name: 'edit_user',
        label: store.getters.translate('edit_user'),
        guard_name: 'sanctum',
        model: 'user'
    },
    {
        name: 'delete_user',
        label: store.getters.translate('delete_user'),
        guard_name: 'sanctum',
        model: 'user'
    },
    {
        name: 'show_person',
        label: store.getters.translate('show_person'),
        guard_name: 'sanctum',
        model: 'person'
    },
    {
        name: 'create_person',
        label: store.getters.translate('create_person'),
        guard_name: 'sanctum',
        model: 'person'
    },
    {
        name: 'edit_person',
        label: store.getters.translate('edit_person'),
        guard_name: 'sanctum',
        model: 'person'
    },
    {
        name: 'delete_person',
        label: store.getters.translate('delete_person'),
        guard_name: 'sanctum',
        model: 'person'
    },
    {
        name: 'show_emailaddress',
        label: store.getters.translate('show_emailaddress'),
        guard_name: 'sanctum',
        model: 'emailaddress'
    },
    {
        name: 'create_emailaddress',
        label: store.getters.translate('create_emailaddress'),
        guard_name: 'sanctum',
        model: 'emailaddress'
    },
    {
        name: 'edit_emailaddress',
        label: store.getters.translate('edit_emailaddress'),
        guard_name: 'sanctum',
        model: 'emailaddress'
    },
    {
        name: 'delete_emailaddress',
        label: store.getters.translate('delete_emailaddress'),
        guard_name: 'sanctum',
        model: 'emailaddress'
    },
]