<template>
  <v-stepper class="elevation-0">
    <v-stepper-header>
      <v-stepper-step color="primary" :complete="true" step="1">
        {{ $store.getters.translate("created") }}
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step :color="getColor(assigned)" :complete="assigned" step="2">
        {{ $store.getters.translate("assigned") }}
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step :color="getColor(active)" :complete="active" step="3">
        {{ $store.getters.translate("active") }}
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step :color="getColor(closed)" :complete="closed" step="4">
        {{ $store.getters.translate("closed") }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: ["active", "assigned", "closed"],
  methods: {
    getColor(status) {
      return status ? "primary" : "#9E9E9E";
    },
  },
};
</script>