import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import store from './store'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    // This is for the scroll top when click on any router link
    routes: [
        {
            publicPath: '/',
            historyApiFallback: true,
            path: '/',
            component: () => import('./layouts/full-layout/Layout'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name: '403',
                    path: '/403',
                    component: () => import('./views/errors/403'),
                },
                {
                    name: 'Dashboard',
                    path: '/',
                    component: () => import('./views/Dashboard'),
                },
                {
                    name: 'Projects',
                    path: 'projects',
                    meta: { name: 'project', action: 'read' },
                    component: () => import('./views/projects/List'),
                },
                {
                    name: 'Project edit',
                    path: 'projects/:id',
                    meta: { model: 'project' },
                    component: () => import('./views/projects/Edit'),
                },
                {
                    name: 'Planner',
                    path: 'planning',
                    component: () => import('./views/planner/List'),
                },
                {
                    name: 'Punchlists',
                    path: 'punchlists',
                    meta: { name: 'punchlist', action: 'read' },
                    component: () => import('./views/punchlists/List'),
                },
                {
                    name: 'Punchlist edit',
                    path: 'punchlists/:id',
                    meta: { model: 'punchlist' },
                    component: () => import('./views/punchlists/Edit'),
                },
                {
                    name: 'Platforms',
                    path: 'platforms',
                    meta: { name: 'asset', action: 'read' },
                    component: () => import('./views/platforms/List'),
                },
                {
                    name: 'Documents',
                    path: 'documents',
                    meta: { name: 'document', action: 'read' },
                    component: () => import('./views/documents/List'),
                },
                {
                    name: 'Notes',
                    path: 'notes',
                    meta: { model: 'note', action: 'read' },
                    component: () => import('./views/notes/List'),
                },
                {
                    name: 'Search',
                    path: 'search',
                    meta: { model: 'search' },
                    component: () => import('./views/search/List'),
                },
                {
                    name: 'Companies',
                    path: 'companies',
                    meta: { model: 'company', action: 'read' },
                    component: () => import('./views/companies/List'),
                },
                {
                    name: 'Companies edit',
                    path: 'companies/:id',
                    meta: { model: 'company' },
                    component: () => import('./views/companies/Edit'),
                },
                {
                    name: 'Contact',
                    path: 'contacts',
                    meta: { model: 'contact', action: 'read' },
                    component: () => import('./views/contacts/List'),
                },
                {
                    name: 'Contact edit',
                    path: 'contacts/:id',
                    meta: { model: 'contact' },
                    component: () => import('./views/contacts/Edit'),
                },
                {
                    name: 'Employees',
                    path: 'employees',
                    meta: { model: 'employee', action: 'read' },
                    component: () => import('./views/employees/List'),
                },
                {
                    name: 'Employees edit',
                    path: 'employees/:id',
                    meta: { model: 'employee' },
                    component: () => import('./views/employees/Edit'),
                },
                {
                    name: 'Templates',
                    path: 'templates',
                    meta: { model: 'template', action: 'read' },
                    component: () => import('./views/templates/List'),
                },
                {
                    name: 'Templates edit',
                    path: 'templates/:id',
                    meta: { model: 'template' },
                    component: () => import('./views/templates/Edit'),
                },
                {
                    name: 'Translations',
                    path: 'translations',
                    meta: { model: 'translation', action: 'read' },
                    component: () => import('./views/translations/List'),
                },
                {
                    name: 'Fields',
                    path: 'fields',
                    meta: { model: 'field', action: 'read' },
                    component: () => import('./views/fields/List'),
                },
                {
                    name: 'Tags',
                    path: 'tags',
                    meta: { model: 'tag', action: 'read' },
                    component: () => import('./views/tags/List'),
                },
                {
                    name: 'Typesandsubtypes',
                    path: 'typesandsubtypes',
                    meta: { model: 'typesandsubtype', action: 'read' },
                    component: () => import('./views/typesandsubtypes/List'),
                },
                {
                    name: 'Role',
                    path: 'roles',
                    meta: { model: 'role', action: 'read' },
                    component: () => import('./views/roles/List'),
                },
                {
                    name: 'Role Edit',
                    path: 'roles/:id',
                    meta: { model: 'role' },
                    component: () => import('./views/roles/Edit'),
                },
                {
                    name: 'Workflow',
                    path: 'workflows',
                    meta: { model: 'workflow', action: 'read' },
                    component: () => import('./views/workflows/List'),
                },
                {
                    name: 'Workflow Edit',
                    path: 'workflows/:id',
                    meta: { model: 'workflow' },
                    component: () => import('./views/workflows/Edit'),
                },
                {
                    name: 'Settings Edit',
                    path: 'settings',
                    meta: { model: 'setting' },
                    component: () => import('./views/settings/Edit'),
                },
                {
                    name: 'Forms',
                    path: 'forms',
                    meta: { model: 'form', action: 'read' },
                    component: () => import('./views/forms/List'),
                },
                {
                    name: 'Form edit',
                    path: 'forms/:id',
                    meta: { model: 'form' },
                    component: () => import('./views/forms/Edit'),
                },
                {
                    name: 'Equipment',
                    path: 'equipment',
                    meta: { model: 'equipment', action: 'read' },
                    component: () => import('./views/equipment/List'),
                },
                {
                    name: 'Equipment edit',
                    path: 'equipment/:id',
                    meta: { model: 'equipment' },
                    component: () => import('./views/equipment/Edit'),
                },
                {
                    name: 'Field sets',
                    path: 'fieldsets',
                    meta: { model: 'fieldset', action: 'read' },
                    component: () => import('./views/fieldsets/List'),
                },
                {
                    name: 'Field sets edit',
                    path: 'fieldsets/:id',
                    meta: { model: 'fieldset' },
                    component: () => import('./views/fieldsets/Edit'),
                },
                {
                    name: 'Report sections',
                    path: 'reportsections',
                    meta: { model: 'reportsection', action: 'read' },
                    component: () => import('./views/reportsections/List'),
                },
                {
                    name: 'Report sections edit',
                    path: 'reportsections/:id',
                    meta: { model: 'reportsection' },
                    component: () => import('./views/reportsections/Edit'),
                },
                {
                    name: 'Import assets',
                    path: 'importassets',
                    component: () => import('./views/importassets/Index'),
                },
                {
                    name: 'Profile',
                    path: 'profile',
                    component: () => import('./views/Profile'),
                },
                {
                    name: 'Documentation',
                    path: 'documentation',
                    component: () => import('./views/documentation/Index'),
                },
            ]
        },
        {
            name: 'Login',
            path: '/login',
            component: () => import('./views/authentication/Login'),
        },
        {
            name: 'Error',
            path: '/error404',
            component: () => import('./views/Error'),
        },

        {
            path: '/authentication',
            component: () => import('./layouts/blank-layout/Blanklayout'),
        },
        {
            name: 'Complete registration',
            path: '/completeregistration',
            publicPath: '/completeregistration',
            component: () => import('./views/authentication/CompleteRegistration'),
            meta: {
                requiresAuth: false
            },
        },
        {
            name: 'Password reset',
            path: '/resetpassword',
            publicPath: '/resetpassword',
            component: () => import('./views/authentication/PasswordReset'),
            meta: {
                requiresAuth: false
            },
        },
        {
            publicPath: '/',
            historyApiFallback: true,
            path: '/',
            component: () => import('./layouts/full-layout/Layout'),
            children: [
                {
                    name: '403',
                    path: '/403',
                    component: () => import('./views/errors/403'),
                },
            ]
        },
    ],
})

router.beforeEach((to, from, next) => {
    if(to.path == '/completeregistration' || to.path == '/resetpassword') {
        localStorage.removeItem("path");
    }
    else if(to.path !== '/login') {
        var path = to.path;
        if (to.query.id) {
            path += "?id=" + to.query.id;
        }
        localStorage.setItem("path", path);
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        } else {
            next('/login');
        }

    } else {
        next();
    }
})

router.beforeEach((to, from, next) => {
    next();
})


export default router