<template>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="modal_name">
      <v-card :loading="loading" :disabled="loading">
        <BaseModal>
          <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
            <v-btn icon @click="closeModal(modal_name)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text>
              <v-row v-if="loaded_page">
                <v-col>
                  <p style="font-size: 24px; font-weight: 600;">{{ loaded_page.name }}</p>
                  <div v-html="loaded_page.html"/>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["modal_name","selected_chapter", "selected_page"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("documentation"),
        name: "documentation",
        model: "documentation",
      },
      loaded_page: null,
      loading: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.openModal(this.modal_name);
      if(this.selected_chapter && this.selected_chapter.type === 'page') {
        this.getPage(this.selected_chapter);
      }
      else if(this.selected_page) {
        this.getPage(this.selected_page);
      }
    },
    async getPage(page) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/get-page", { page: page.id })
          .then((response) => {
            this.loading = false;
            this.loaded_page = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  }
};
</script>