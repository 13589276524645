<template>
  <div>
    <v-textarea
      class="mt-5"
      :label="$store.getters.translate('send_notification')"
      v-model="new_value"
      :items="messagetemplates"
      outlined
      dense
      single-line
      rows="10"/>
    <v-btn text block color="primary" @click="openModal(modal_name); showHandler()">
      {{ $store.getters.translate("choose_from_message_templates") }}
    </v-btn>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="modal_name">
      <v-card :loading="loading">
        <BaseModal>
          <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
            <v-toolbar-title>{{ $store.getters.translate("messagetemplates") }} {{ $store.getters.translate(module_name) }}</v-toolbar-title>
            <v-spacer/>
            <v-btn text small @click="closeModal(modal_name); showHandler()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text v-if="!show_create">
              <v-data-table
                  :headers="headers"
                  :items="messagetemplates"
                  :loading="loading"
                  selectable-key="id"
                  hide-default-footer>
                <template v-slot:item.name="{ item }">
                  <a @click="setMessage(item.message)">{{ item.name }}</a>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteRecord(item.id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
          <v-footer slot="footer" v-if="!show_create">
            <v-btn text block color="primary" @click="show_create = true">
              {{ $store.getters.translate("create") }}
            </v-btn>
          </v-footer>
          <template slot="footer" v-if="show_create">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  class="mt-5"
                  rows="10"
                  :label="$store.getters.translate('notification')"
                  v-model="record.message"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-footer>
            <v-btn text block color="primary" @click="save()">
              {{ $store.getters.translate("save") }}
            </v-btn>
          </v-footer>
        </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["value", "module_name", "single","modal_name"],
  data() {
    return {
      new_value: [],
      messagetemplates: [],
      loading: false,
      show_create: false,
      record: {},
      headers: [
        {
          text: this.$store.getters.translate("name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$store.getters.translate("actions"),
          sortable: false,
          align: "right",
          value: "actions",
        },
      ],
    };
  },
  created() {
    this.new_value = this.value;
    this.getMessageTemplates();
  },
  methods: {
    ...helpFunctions.modal_functions,
    update() {
      this.$emit("input", this.new_value);
      this.$emit("change");
    },
    save() {
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/messagetemplates", this.record)
        .then((response) => {
          this.loading = false;
          this.getMessageTemplates();
          if (response.status == 200) {
            this.show_create = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.setMessage(this.record.message);
            this.record.message = "";
          } else {
            var errorMessage = response.data.message;
            var errors = Object.keys(response.data.message);
            this.$toasted.error([errorMessage[errors[0]]]);
          }
        }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async getMessageTemplates() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/messagetemplates")
        .then((response) => {
          this.loading = false;
          this.messagetemplates = response.data;
          this.$emit("messagetemplates", this.messagetemplates);
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    deleteRecord(id) {
      this.loading = true;
      this.$http
        .delete(this.$store.getters.appUrl + "v2/messagetemplates/" + id)
        .then(() => {
          this.loading = false;
          this.getMessageTemplates();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    setMessage(message) {
      this.closeModal(this.modal_name);
      this.showHandler();
      this.new_value = message;
    },
    showHandler() {
      this.getMessageTemplates();
    },
  },
  watch: {
    new_value: {
      handler() {
        this.update();
      },
    },
    value: {
      handler() {
        this.new_value = this.value;
      },
    },
  },
};
</script>