<template>
  <div>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-progress-circular v-if="loading" :size="75" color="primary" indeterminate/>
        <div v-else class="user mt-2">
          <v-img @click="$refs.FileInput.click()" :src="getUrl" class="profile-img"/>
          <v-icon v-if="hasImage" @click="deleteImage()" class="icon primary white--text">mdi-close</v-icon>
          <input @change="onFileSelect" ref="FileInput" type="file" style="display: none"/>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="person">
      <v-col style="text-align: center" class="mr-5 ml-5">
        <h3 class="mb-5">{{ person.name }}</h3>
        <small v-if="person.user && person.user.last_login" small>
          {{ $store.getters.translate("last_login") }}:
          {{ moment(person.user.last_login).format("DD-MM-YYYY H:m") }}
        </small>
        <br v-if="person.user && person.user.last_login" />
        <v-chip
          v-if="person.mobilenumber"
          class="mt-2 mr-2"
          label
          outlined
          small
          color="secondary">
          <v-icon small left>mdi-cellphone</v-icon>
          {{ person.mobilenumber }}
        </v-chip>
        <v-chip
          v-if="person.phonenumber"
          class="mt-2 mr-2"
          label
          outlined
          small
          color="secondary">
          <v-icon small left>mdi-phone</v-icon>
          {{ person.phonenumber }}
        </v-chip>
        <v-chip
            v-if="person.user && person.user.email"
            :key="person.user.id"
            class="mt-2 mr-2"
            label
            small
            color="success">
          <v-icon small left>mdi-email</v-icon>
          {{ person.user.email }}
        </v-chip>
        <template v-if="person.user">
          <template v-for="(email, index) in getEmailAddresses">
            <v-chip
                v-if="email.emailaddress != person.user.email"
                :key="index"
                class="mt-2 mr-2"
                label
                small
                color="secondary">
              <v-icon small left>mdi-email</v-icon>
              {{ email.emailaddress }}
            </v-chip>
          </template>
        </template>
        <template v-else>
          <template v-for="(email, index) in getEmailAddresses">
            <v-chip
                :key="index"
                class="mt-2 mr-2"
                label
                small
                color="secondary">
              <v-icon small left>mdi-email</v-icon>
              {{ email.emailaddress }}
            </v-chip>
          </template>
        </template>
      </v-col>
    </v-row>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="modal_name">
      <v-card>
        <BaseModal>
          <v-toolbar slot="header" class="draggable_selector" dark color="primary" dense flat>
            <v-toolbar-title>{{ $store.getters.translate("edit_avatar") }}</v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="closeModal(modal_name)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text slot="content">
            <VueCropper
              v-show="selectedFile"
              ref="cropper"
              :src="selectedFile"
              alt="Source Image"/>
          </v-card-text>
          <v-card-actions slot="footer">
            <v-btn class="primary" @click="saveImage(); closeModal(modal_name)">{{ $store.getters.translate("save") }}</v-btn>
            <v-btn color="primary" text @click="closeModal(modal_name)">{{ $store.getters.translate("cancel") }}</v-btn>
          </v-card-actions>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";
export default {
  components: {
    VueCropper,
    BaseModal,
    BaseDraggableModal,
  },
  props: ["url", "id", "module_name", "person", "modal_name"],
  data() {
    return {
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      files: "",
      loading: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    saveImage() {
      this.loading = true;
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append("avatar", blob, "name.jpeg");
        this.$http
          .post(this.$store.getters.appUrl + "v2/people/file?id=" + this.person.id, formData, {headers: { "Content-Type": "multipart/form-data" }})
          .then(() => {
            this.$emit("refresh");
            this.loading = false;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
      }, this.mime_type);
    },
    deleteImage() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/people/file/delete?id=" + this.person.id)
        .then(() => {
          this.$emit("refresh");
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    onFileSelect(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        this.mime_type = file.type;
        if (typeof FileReader === "function") {
          this.openModal(this.modal_name);
          const reader = new FileReader();
          reader.onload = (event) => {
            this.selectedFile = event.target.result;
            this.$refs.cropper.replace(this.selectedFile);
          };
          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      }
    },
    setActiveEmail(email) {
      this.person.set_active_email = email;
      this.$http
        .patch(this.$store.getters.appUrl + "v2/people/" + this.person.id, this.person)
        .then(() => {
          this.$emit("refresh");
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
  },
  computed: {
    getUrl() {
      if (this.person && this.person.file) {
        return this.person.file.full_path;
      }
      return "../../../assets/images/no-user.jpg";
    },
    hasImage() {
      if (this.person && this.person.file) {
        return true;
      }
      return false;
    },
    getEmailAddresses() {
      return this.person.emailaddresses;
    },
  },
};
</script>
<style scoped>
.user {
  width: 125px;
  height: 125px;
  border-radius: 100%;
  border: 3px solid lightgray;
  position: relative;
  text-align: center;
}
.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 10px;
}
.icon {
  position: absolute;
  top: 10px;
  right: 0;
  background: #e2e2e2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  color: #0000ff;
  font-size: 14px;
  cursor: pointer;
}
</style>