<template>
  <v-dialog v-model="show" width="75%" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable @click:outside="reset(); resetManagerFields()">
    <v-card :loading="loading">
      <v-toolbar dark color="primary" dense flat>
        <v-btn icon @click="reset(); resetManagerFields()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ page.title }}</v-toolbar-title>
        <v-spacer/>
        <v-btn
            :disabled="loading"
            text small @click="save()">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" dark icons-and-text>
            <v-tabs-slider color="yellow"/>
            <v-tab v-for="item in tabItems" :key="item">
              {{ item.label }}<v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col>
            <v-tabs-items v-model="tab">
              <v-tab-item :key="0">
                <permission-list :items="records" :fields="fields" :tableLoading="tableLoading" />
              </v-tab-item>
              <v-tab-item :key="1">
                <v-autocomplete
                    :mandatory="true"
                    :disabled="availableCreationOptions.users.isDisabled"
                    v-model="record.user_id"
                    :items="availableCreationOptions.users.items"
                    :loading="availableCreationOptions.users.isLoading"
                    hide-no-data
                    hide-selected
                    item-text="label"
                    item-value="text"
                    :label="$store.getters.translate('user')"
                    :placeholder="`Start typing to Search ${$store.getters.translate('user')}`"/>
                <v-divider/>
                <permission-list :items="records" :fields="fields" :tableLoading="tableLoading" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
      <slot>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>

import defineRulesFor from "../../ability";
import PermissionList from "./MiniList";
import lodash from "lodash";

export default {
  props: ["parent", "parent_name", "selected_record"],
  components: {PermissionList},
  data() {
    return {
      state: {
        selectedUser : 0,
      },
      tab: 0,
      tabItems : [
        {
          'label' : 'By Roles',
          'icon' : 'mdi-account-group',
        },
        {
          'label' : 'By Users',
          'icon' : 'mdi-account-circle',
        },
      ],
      tableLoading: false,
      orderColumns:{
        'show' : 1,
        'create' : 2,
        'edit' : 3,
        'delete' : 4,
      },
      fields: [],
      additionalFields: [],
      defaultField : {
        text: this.$store.getters.translate('roles'),
        value: 'roles',
        order: 0,
      },
      list: {
        actions: {
          items : [],
          isLoading : false,
        },
        roles: {
          items : [],
          isLoading : false,
        },
      },
      allowedPermissions: [],
      total_records: [],
      records: [],
      shortListOptions: {
        'entity_item_id' : 0,
        'entity_id' : 0,
      },
      page: {
        title: this.$store.getters.translate("create_custom_permission"),
        name: "custom_permissions",
      },
      record: {},
      searchable: {
        entities : '',
        timer: null
      },
      availableCreationOptions : {
        entities: {
          items : [],
          isLoading : false,
          isDisabled : false,
          defaultItem : null,
          additionalUrlParams : [],
        },
        users: {
          items : [],
          isLoading : false,
          isDisabled : false,
          defaultItem : {
            'label' : 'Not Selected',
            'text' : 0
          },
          additionalUrlParams : [],
        },
      },
      excludedFromSearch: [
          'higherOrder',
      ],
      loading: false,
      create: false,
      show: false,
      templates: [],
      managerOptions: null,
    };
  },
  beforeUpdate() {
    if(this.selected_record && this.record.entity === null) {
      const {assoc, fieldOptions} = this.selected_record;
      for (let key in this.selected_record.record) {
        if(Object.hasOwnProperty.call(this.selected_record.record, key)) {
          this.record[assoc[key]] = this.selected_record.record[key] === 'any' ? 0 : (
              !isNaN(this.selected_record.record[key]) ? +this.selected_record.record[key]
                : this.selected_record.record[key]
            );
        }
      }
      if(fieldOptions['disabled']) {
        for(let field of fieldOptions['disabled']) {
          this.availableCreationOptions[field].isDisabled = true;
        }
      }
    }
  },
  created() {
    for(let target of Object.keys(this.availableCreationOptions)) {
      if(this.excludedFromSearch.indexOf(target) === -1) {
        this.searchTarget(target);
      }
    }
    this.load();
  },
  methods: {
    load: lodash.debounce(function (func = null) {
      this.run(func);
    }, 100),
    getOrder(action) {
      switch (action) {
        case 'show' :
        case 'create' :
        case 'edit' :
        case 'delete' :
          return this.orderColumns[action];
        case 'first' :
          return 0;
        default :
          return 999;
      }
    },
    run(func) {
      this.tableLoading = true;
      if(this.list.actions.items.length === 0) {
        this.loading = true;
        this.$http
            .get(this.$store.getters.appUrl + "v2/custom_permission_actions")
            .then((response) => {
              this.loading = false;
              this.list.actions.items = response.data;
              this.additionalFields = [];
              this.list.actions.items.forEach(item => {
                this.additionalFields.push({
                  text: this.$store.getters.translate(item.name),
                  align: "center",
                  value: item.id,
                  order: this.getOrder(item.name),
                })
              });
              this.additionalFields = this.$lodash.sortBy(this.additionalFields, item => item.order)
              this.fields = [this.defaultField, ...this.additionalFields];
              this.list.actions.isLoading = false;
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
      if(this.list.roles.items.length === 0) {
        this.$http
            .get(this.$store.getters.appUrl + "v2/custom_permission_roles")
            .then((response) => {
              this.loading = false;
              this.list.roles.items = response.data;
              this.list.roles.isLoading = false;
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
      this.tableLoading = false;
      if(func) {
        func();
      }
    },
    loadRecords() {
      this.records = [];
      this.total_records = [];

      let url, addParams = `?entityId=${this.record.entity}&entityItemId=${this.record.entity_id}&userId=${this.record.user_id}`;
      switch (this.tab) {
        case 0:
          url = this.$store.getters.appUrl + `v2/custom_permissions_by_entity_item`;
          break;
        case 1:
          url = this.$store.getters.appUrl + `v2/custom_permissions_by_entity_item_and_user`;
          break;
        default:
          url = 'not_set';
      }
      url = `${url}${addParams}`
      this.tableLoading = true;
      this.$emit('disableManaging', true);
      this.loading = true;
      this.$http
          .get(url)
          .then((response) => {
            this.loading = false;
            const {data : parsedResponse} = response;
            const pseudoFilled = parsedResponse['pseudo_filled'] || null;
            parsedResponse.permissions.forEach( permission => {
              if(permission.status) {
                this.allowedPermissions.push(permission.id);
              }
              const newItem = {
                type: 'checkbox',
                ...permission,
                'selected' : permission.status ? permission.id : null,
                'order' : this.getOrder(permission['action_name']),
                'action' : permission['custom_permission_action_id'],
              }
              const roleName = +this.tab === 0 ? permission['role_name'].replace(/\s#\d+/, '') : 'byUser';
              if (this.total_records[roleName]) {
                this.total_records[roleName].push(newItem)
              } else {
                this.total_records[roleName] = [newItem];
              }
            });
            let roles
            switch (this.tab) {
              case 0: {
                roles = this.list.roles.items;
                break;
              }
              case 1: {
                roles = [{
                  'name' : 'byUser'
                }];
                break;
              }
              default:
            }
            const preparedActions = {};
            this.list.actions.items.forEach(i => {
              preparedActions[i.name] = i.id;
            });
            roles.forEach(role => {
              const currentColumns = this.total_records[role.name] ? this.total_records[role.name] : [];
              const currentColumnsActions = currentColumns.map(i => i['action_name']);
              const otherColumns = this.list.actions.items
                .filter(i => !currentColumnsActions.includes(i.name))
                .map( i => {
                  return {
                    'roleId' : role.id || null,
                    'type': 'checkbox',
                    'selected' : false,
                    'order' : this.getOrder(i.name),
                    'action' : preparedActions[i.name],
                  }
              })
              const defaultColumn = +this.tab === 0 ? [{
                type: 'text',
                label: this.$store.getters.translate(role.name),
                text: role.name,
                roleId: role.id,
                order: 0,
              }] : [];
              const newRecord = {
                columns: [
                  ...defaultColumn,
                  ...currentColumns,
                  ...otherColumns,
                ]
              };
              if(pseudoFilled) {
                newRecord.columns = newRecord.columns.map(column => {
                  if(Object.hasOwnProperty.call(column, 'id')) {
                    return column;
                  } else if(column['type'] === 'checkbox') {
                    const equivalent = pseudoFilled.filter(item => {
                      return +column['action'] === +item['custom_permission_action_id']
                          && (column['roleId'] ? +column['roleId'] === +item['specified_for_role_id'] : true);
                    })[0] || null;
                    if(equivalent) {
                      column.selected = equivalent.status;
                      column.class = 'pseudo-filled';
                      return column;
                    } else {
                      return column;
                    }
                  }
                  return column;
                });
              }
              newRecord.columns = this.$lodash.sortBy(newRecord.columns, item => item.order);
              this.records.push(newRecord)
            });
          }).finally(async () => {
            this.loading = false;
            this.tableLoading = false;
            const disManaging = new Promise((resolve) => {
              setTimeout(() => {
                this.$emit('disableManaging', false)
                resolve('some');
              }, 0);
            });
            await Promise.any([disManaging]);
        });
    },
    updateAllowedPermissions(permissionsOnUpdate) {
      const {toDisallow, toAllow} = permissionsOnUpdate;
      this.allowedPermissions = [
        ...this.allowedPermissions.filter( id => !toDisallow.includes(id)),
        ...toAllow
      ];
    },
    getPermissionsChanges() {
      const toDisallow = [], toAllow = [], other = [];
      this.records.forEach( record => {
        const firstColumn = record.columns[0];
        record.columns.forEach( column => {
          if(column.type === 'checkbox') {
            if(column.id) {
              if(this.allowedPermissions.includes(column.id)) {
                if(column.selected !== column.id) {
                  toDisallow.push(column.id);
                }
              } else {
                if(column.selected === column.id) {
                  toAllow.push(column.id);
                }
              }
            } else {
              const roleId = firstColumn.roleId || 0;
              let can = true;

              if(column.class && column.class.includes('pseudo-filled') && !!column.selected) {
                can = false;
              }
              if(can) {
                other.push({
                  'roleId' : roleId,
                  'status' : !!column.selected,
                  'action' : column.action,
                });
              }
            }
          }
        });
      });
      return {
        toDisallow,
        toAllow,
        other,
      }
    },
    updatePermissions(refresh) {
      this.$http
        .get(this.$store.getters.appUrl + "v2/abilities")
        .then((response) => {
          this.ability.update(defineRulesFor(response.data));
          if(refresh){
            refresh();
          }
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    searchTarget(target) {
      this.availableCreationOptions[target].isLoading = true;
      const entitiesWasDisabled = this.availableCreationOptions.entities.isDisabled;
      if(target === 'entityItems' && !entitiesWasDisabled) {
        this.availableCreationOptions.entities.isDisabled = true;
      }
      const hasAdditionalUrlParams = !!this.availableCreationOptions[target]['additionalUrlParams'],
        preparedAdditionalUrlParams = hasAdditionalUrlParams
          ? '?' + this.availableCreationOptions[target]['additionalUrlParams'].map(
            i => {
              let responseString = '';
              for(let key in i) {
                if(Object.hasOwnProperty.call(i, key)) {
                  let value = this;
                  for(let pathPart of i[key].split('.')) {
                    value = value[pathPart];
                  }

                  let newPart = `${key}=${value}`;
                  responseString += responseString ? `&${newPart}` : newPart;
                }
              }
              return responseString;
            }).join('&')
          : '';
      const routeUrl = this.page.name.replace(/s$/,'') + `_${target}${preparedAdditionalUrlParams}`;
      this.$http
          .get(this.$store.getters.appUrl + `v2/${routeUrl}`)
          .then((response) => {
            this.setItems(target, response.data);
            this.availableCreationOptions[target].isLoading = false;
            if(target === 'entityItems' && !entitiesWasDisabled) {
              this.availableCreationOptions.entities.isDisabled = false;
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    setItems(target, items) {
      const wrappedItems = items.map( item => {
        return {
          'label' : this.$store.getters.translate(item.name),
          'pureLabel' : item.name,
          'text' : item.id
        };
      });
      this.availableCreationOptions[target]['items'] = this.$lodash.sortBy([
          ...this.availableCreationOptions[target]['defaultItem'] ? [this.availableCreationOptions[target]['defaultItem']] : [],
          ...wrappedItems
      ]);
    },
    save() {
      this.loading = true;
      const permissionsOnUpdate = this.getPermissionsChanges();
      this.$http
          .post(this.$store.getters.appUrl + "v2/custom_permissions_bulk", {
            ...permissionsOnUpdate,
            'record' : {
              ...this.record,
              'user_id' : +this.tab === 1 ? this.record['user_id'] : 0,
            }
          }).then((response) => {
            this.loading = false;
            if (+response.status === 200) {
              this.$toasted.success(this.$store.getters.translate("successfully_saved"));
              this.resetManagerFields();
              this.reset(()=>{this.$emit("refresh", response.data);});
              this.updateAllowedPermissions(permissionsOnUpdate);
            } else {
              var errorMessage = response.data.message;
              var errors = Object.keys(response.data.message);
              this.$toasted.error([errorMessage[errors[0]]]);
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    selectAll() {
      this.record.permissions = this.$lodash.map(this.$permissions, "name");
    },
    resetManagerFields() {
      if(this.selected_record && this.selected_record.isManagerModal){
        this.record = {
          user_id: 0,
          entity_id: 0,
          entity: null,
        };
      }
    },
    resetRecords() {
      this.records = [];
      this.total_records = [];
    },
    reset(refresh = null, pure = false) {
      if(!pure) {
        this.updatePermissions(refresh);
      }

      this.record = {
        user_id: 0,
        entity_id: 0,
        entity: null,
      };

      this.records = [];
      this.total_records = [];
      this.show = false;
    },
    checkLoading() {
      this.tableLoading = !(!this.list.roles.isLoading && !this.list.actions.isLoading);
    },
  },
  watch: {
    'list.roles.isLoading': function () {
      this.checkLoading();
    },
    'list.actions.isLoading': function () {
      this.checkLoading();
    },
    'searchable.entities': function (newValue, oldValue) {
      if(!!newValue && newValue !== oldValue) {
        clearTimeout(this.searchable.timer);
        if(!this.availableCreationOptions.entities.isDisabled) {
          this.searchable.timer = setTimeout(() => {
            this.searchTarget('entityItems');
          },800);
        }
      }
    },
    'record.user_id': function (newValue) {
      if(+newValue !== 0) {
        this.loadRecords();
      } else {
        this.resetRecords();
      }
    },
    'managerOptions' : function(newValue) {
      if(newValue) {
        const entity = this.availableCreationOptions.entities.items.filter(item => {
          return item.pureLabel === newValue.entityModel;
        });
        if(entity) {
          this.record.entity = entity[0]['text'];
          this.record.entity_id = newValue.entityId;
          this.records = [];

          this.load(this.loadRecords);
        }
      }
    },
    'tab': function(tabIndex) {
      if(+tabIndex === 0) {
        this.state.selectedUser = 0;
        this.fields = [this.defaultField, ...this.additionalFields];
      } else {
        this.fields = [...this.additionalFields];
      }

      if(+this.record.user_id !== 0 || +tabIndex === 0) {
        this.loadRecords();
      } else {
        this.resetRecords();
      }
    },
  },
  computed: {
    getRecords() {
      return this.records;
    },
    currentRecordName() {
      return this.record.name;
    },
  },
};
</script>

<style scoped>
.text-center >>> .v-input__slot {
  align-items: center;
  justify-content: center;
}
</style>