<template>
  <v-form ref="form" v-model="valid">
    <v-row v-for="(address, index) in updated_addresses" :key="index">
      <v-col cols="12" sm="4" md="2">
        <v-text-field
          :label="$store.getters.translate('number')"
          v-model="address.number"/>
      </v-col>
      <v-col cols="12" sm="8" md="10">
        <base-row
          :label="$store.getters.translate('address')"
          :value="address.formatted_address"
          :deletable="true"
          @delete="deleteAddress(address)"
          :prepend="'mdi-google-maps'"
          :hide_copy="true"/>
      </v-col>
    </v-row>
    <vuetify-google-autocomplete
      id="map"
      append-outer-icon="mdi-google-maps"
      classname="form-control"
      :disabled="false"
      v-on:placechanged="addAddress"
      country="nl"
      ref="new_address"
      :placeholder="$store.getters.translate('type_address')">
    </vuetify-google-autocomplete>
  </v-form>
</template>

<script>
export default {
  props: ["value","is_submit"],
  data() {
    return {
      updated_addresses: [],
      loading: false,
      valid: true,
    };
  },
  mounted() {
    this.updated_addresses = this.value;
  },
  methods: {
    update() {
      this.$emit("input", this.updated_addresses);
    },
    addAddress(addressData, placeResultData) {      
      let address = {
        street: addressData.route,
        zipcode: addressData.postal_code,
        number: addressData.street_number,
        city: addressData.locality,
        municipality: addressData.administrative_area_level_2,
        latitude: addressData.latitude,
        longitude: addressData.longitude,
        country: 'NL',
        formatted_address: placeResultData.formatted_address,
        name: placeResultData.formatted_address,
        type: "visit",
      };
      if (!this.updated_addresses.find((address) => address.formatted_address == placeResultData.formatted_address)) {
        this.updated_addresses.push(address);
        this.$refs.new_address.clear();
        this.update();
      }
    },
    deleteAddress(old_address) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
            if (res) {
                this.updated_addresses = this.updated_addresses.filter((address) => address.id != old_address.id || address.formatted_address != old_address.formatted_address);
                if (old_address.id) {
                  this.$http
                    .delete(this.$store.getters.appUrl + "v2/addresses/" + old_address.id)
                    .then(() => {
                      this.loading = false;
                      this.$toasted.success(this.$store.getters.translate("success"));
                    }).catch((error) => {
                      if (this.$store.getters.isLoggedIn) {
                        this.$toasted.error(error);
                      }
                      this.loading = false;
                    });
                }
                this.update();
            }
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.updated_addresses = this.value;
      },
    },
    is_submit:function() {
      this.$refs.new_address.clear();
    }
  },
};
</script>