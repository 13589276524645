<template>
  <div class="mb-5">
    <small>{{ $store.getters.translate(label) }}</small>
    <div style="margin-top: 20px" class="float-right">
      <v-icon v-if="label != 'file' && !hide_copy" @click="copy">mdi-clipboard-text-outline</v-icon>
      <v-icon v-if="deletable" @click="$emit('delete', value)" color="error">mdi-trash-can</v-icon>
    </div>
    <span class="value" v-if="!link">{{ getValue }}</span>
    <a class="link" v-else @click="$emit('click')">{{ getValue }}</a>
    <v-divider light/>
  </div>
</template>

<script>
export default {
  props: [
    "label",
    "value",
    "link",
    "deletable",
    "hide_copy",
  ],
  methods: {
    copy() {
      this.$clipboard(this.value);
      this.$toasted.info(this.$store.getters.translate("copied_to_clipboard"));
    },
  },
  computed: {
    getValue() {
      if (!this.value) {
        return "-";
      }
      return this.value;
    },
  },
};
</script>

<style scoped>
.base-row {
  margin-bottom: 12px;
}
.base-row small {
  display: block;
}

.value {
  display: block;
  color: #525252;
  font-size: 16px;
  margin-bottom: 8px !important;
}

.link {
  display: block !important;
  font-size: 16px;
  margin-bottom: 8px !important;
}
</style>